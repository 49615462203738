import {useEffect, useState} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";
import {DataGridPro, getGridStringOperators} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import sendRequest from "../services/sendRequest";
import getOptions from "../services/getOptions";
import EditData from "../components/editData";
import ReportGen from "../components/reportGen";

import CustomToolbar from "../components/customToolbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import UnknownDealUpload from "../components/unknownDealUpload";
import DealExport from "../components/dealExport";
const DealsPage = ({
  page,
  columnsToShow,
  defaultParams,
  customColumns = {},
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams({});
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editOrder, setEditOrder] = useState([]);
  const [columns, setColumns] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState();
  const [options, setOptions] = useState({});
  const [rowToEdit, setRowToEdit] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [openUnknownDealUpload, setOpenUnknownDealUpload] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [progress, setProgress] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(500);

  const [openReportGen, setOpenReportGen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [selected, setSelected] = useState([]);

  const [reportList, setReportList] = useState([]);
  const [unSent, setUnSent] = useState([]);
  const [open, setOpen] = useState(false);
  const [checkSent, setCheckSent] = useState(false);
  const [sentReports, setSentReports] = useState([]);
  const [entries, setEntries] = useState([]);
  const [time, setTime] = useState(0);  
  const [canSendEmails, setCanSendEmails] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: page === "job" ? "desc" : "asc",
    },
  ]);
  const [openDealExport, setOpenDealExport] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const role=localStorage.getItem("role")
  console.log("DealsPage",page)
  console.log("DealsPage",data)
  localStorage.setItem('page',page)
  if (page === "deals") {
    if (data.length > 0) {
      const bulkDeals = localStorage.getItem('bulk_deals');
      if (bulkDeals) {
        const bulkDealsArray = JSON.parse(bulkDeals);
        if (bulkDealsArray.length > 0) {
          setData([...data, ...bulkDealsArray])
        }
      }
    }
    localStorage.setItem('bulk_deals',[]);
  }
  
  const setOpenDealsProgrammeExport = () => {
    setOpenDealExport(false)
    setSelectedRows([]);
    setSelected([])
    setEntries([]);
  };

  useEffect(() => {
    localStorage.setItem('programmes_filter', JSON.stringify(false));
    console.log("working...........")
    let fetched = false;
    async function getData() {
      setLoading(true);
      if(page==="users")
        await getOptions("permission");
      const metadata = await getOptions(page);
      console.log("metadatametadatametadata",metadata)
      if (!metadata) {
        enqueueSnackbar("Failed to load Data Options!", {variant: "error"});
        return;
      }
      console.log(metadata);
      setOptions(metadata);

      const cols = await createColumns(metadata);
      setColumns(cols);

      const cvm = await createColumnVisibilityModel(metadata);
      setColumnVisibilityModel(cvm ?? false);

      const notReadOnly = Object.entries(metadata)
        .filter(([_, v]) => !v.read_only)
        .map(([k, _]) => k);
      setEditOrder(notReadOnly);
      let response=[];
      
      response = await sendRequest({
        requestType: "get",
        endpoint: `${page}/?${
          page === "task" ? "latest=true&" : ""
        }${searchParams.toString()}`,
      });

      console.log(response);

      if (
        page === "task" &&
        response.data.some(
          (item) => item.status === "running" || item.status === "queued"
        )
      ) {
        setRefresh((oldState) => !oldState);
      }
      if (response.code) {
        enqueueSnackbar("Failed to load Data!", {variant: "error"});
      } else {
        if(page==="users")
            response.data.forEach(user => {
              const data1 = data.find(roleUser => roleUser.id === user.id);
              if (data1) {
                user.role = data1.role;  // Add the role if found
              } else {
                user.role = 'default';  // Assign a default role if not found
              }
            });
        console.log(response);
        if (page != "programmes") {
          setData(response.data);
        }
      }
    }

    if (fetched) return;
    getData().finally(() => setLoading(false));
    return () => (fetched = true);
  }, [page, refresh]);

  const createColumnVisibilityModel = async (ops) => {
    const model = {};
    Object.keys(ops).forEach((columnName) => {
      if (columnsToShow && !columnsToShow.includes(columnName))
        model[columnName] = false;
    });
    return model;
  };

  const createColumns = async (ops) => [
    ...Object.keys(ops).map((header) => {
      let currentCol = {
        field: header,
        headerName: ops[header].label,
        flex: 1,
      };

      if (header in customColumns) {
        currentCol = {...currentCol, ...customColumns[header]};
      }
      return currentCol;
    }),
    ...(page === "job"
      ? [
          {
            field: "",
            headerName: "View",
            width: 150,

            renderCell: ({row}) => (
              <Button onClick={() => viewJob(row)}>View</Button>
            ),
          },
        ]
      : page === "quarterly_report" || role != "full access"
      ? []
      : [
          {
            field: "",
            headerName: "Edit",
            width: 150,
            filterable: false,
            renderCell: ({row}) => (
              <EditIcon onClick={() => openEditDialogue(row)} />
            ),
          },
        ]),
  ];

  const viewJob = (job) => {
    if (job?.id) {
      navigate(`/task?job=${job.id}`);
    } else {
      enqueueSnackbar("Invalid Job ID", {variant: "warning"});
    }
  };

  const openEditDialogue = async (rowToEdit) => {
    let entity_id = null;
    if (!rowToEdit) {
      setOpenEdit(false);
    } else if (rowToEdit === "add") {
      setOpenEdit(true);
      setIsAdd(true);
      setRowToEdit(rowToEdit);
    } else {
      if(page === "deals") {
        localStorage.setItem('entity_id', rowToEdit.custom_id_stem)
        entity_id = rowToEdit.custom_id_stem;
        localStorage.setItem("rulesForCompare", JSON.stringify(rowToEdit.rules));
      } else {
        localStorage.setItem('entity_id', null) 
      }
      const auditPage = 'audit'
      await sendRequest({
        requestType: "get",
        endpoint: `${auditPage}/?entity_id=${entity_id}&page=${page}`
      }).then(response => {
        const dummyData = Array.isArray(response) ? response : response.data;
        console.log(dummyData);
        localStorage.setItem('audit', JSON.stringify(dummyData));
      
        // Now you can safely use map
        dummyData.map((row, index) => {
          // Your mapping logic
        });
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
      setOpenEdit(true);
      setIsAdd(false);
      setRowToEdit(rowToEdit);
    }
  };

  const selectedRowsForAssetAndUnknowAsset = (model) => {
    if(page === 'deals') {
      const seledtedRows = data.filter((x) => model.some((y) => y === x.custom_id_stem));
      setSelectedRows(seledtedRows)
    }
  };

  
  
  

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSizeNumber) => {
    setPageSize(pageSizeNumber);
  };

  if (!columns) {
    return null;
  }

  const handleSendReport = async () => {
    let successfulReportsCount = 0;
    setEntries([]);
    setSelected([]);
    setOpen(false);
    setLoading(true);

    const chunkSize = 10; // Number of items per API call
    for (let i = 0; i < reportList.length; i += chunkSize) {
        const chunk = reportList.slice(i, i + chunkSize);

        if (chunk.length > 0) {
            const beforeStatus = await sendRequest({
                requestType: "post",
                endpoint: "email_status_update_before",
                requestData: chunk,
            });

        const response = await sendRequest({
            requestType: "post",
            endpoint: "email",
            requestData: chunk,
        });

        if (response && response.data && response.data.response_data && response.data.response_data.length > 0) {
            const sensitiveInfo = await sendRequest({
                requestType: "post",
                endpoint: "email_status_update",
                requestData: response.data.response_data,
            });
            console.log(sensitiveInfo)
            if (sensitiveInfo.status === 200) {
              successfulReportsCount += chunk.length; // Increment the successful reports 
              handleSuccess(successfulReportsCount,reportList.length);
            } else {
              handleError();
            }
        }
    }    
  }
  setLoading(false);
}; 

  const handleError = () => {
    enqueueSnackbar('There is an error with the deals. Please check the reports and resend them!', {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleSuccess = (successfulReportsCount,totalCount) => {
    enqueueSnackbar(`Reports sent successfully: ${successfulReportsCount} out of ${totalCount}`, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 3000,
    });
  };

  const handleUnsentReports = async () => {
    let UnsentReportsSuccessfulCount = 0;
    setEntries([]);
    setSelected([]);
    setOpen(false);
    setLoading(true);
     
    const batchSize = 10; // Number of records to send per batch
    for (let i = 0; i < unSent.length; i += batchSize) {
      const batch = unSent.slice(i, i + batchSize);
  
      if (batch.length > 0) {
        const beforeStatus = await sendRequest({
          requestType: "post",
          endpoint: "email_status_update_before",
          requestData: batch,
        });
  
        const response = await sendRequest({
          requestType: "post",
          endpoint: "email",
          requestData: batch,
        });
  
        if (response && response.data && response.data.response_data && response.data.response_data.length > 0) {
          const updateStatus = await sendRequest({
            requestType: "post",
            endpoint: "email_status_update",
            requestData: response.data.response_data,
          });

          if (updateStatus.status === 200) {
            UnsentReportsSuccessfulCount += batch.length; 
            handleSuccess(UnsentReportsSuccessfulCount,unSent.length);
          } else {
            handleError();
          }
        }        
      }
    }
  
    setLoading(false);
    console.log('All batches processed');
  };

  console.log(reportList);
  console.log(unSent);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleQuickFilter = async (search_data) => {
      let newData = [];
      let query = "where custom_id ILIKE '%"+search_data+"%' or title ILIKE '%"+search_data+"%'"
      let encodedQuery = encodeURIComponent(query);
      const endpoint = `${page}/get_programmes/?offset=${0}&limit=2500&filters=${encodedQuery}`
      const response = await sendRequest({
        requestType: "get",
        endpoint: endpoint,
      });
      setLoading(false);
      if (response.data && response.data && response.data.result.length > 0) {
       newData = [...newData, ...response.data.result];
       const flag = JSON.parse(localStorage.getItem('programmes_filter'));
       setData(newData)           
      }  
  };
  const reportId = (row) => `${row.deal}${row.quarter}${row.year}`;
  //const uniqId = (row) => `${row.year}` + Math.random();

  const defaultId = (row) => {
    if (defaultParams && "row_id" in defaultParams)
      return defaultParams["row_id"];
    return row.id
      ? row.id
      : row.custom_id_stem
      ? row.custom_id_stem
      : row.custom_id;
  };
  return (
    <>

      <Box sx={{height: "100%", width: "100%"}}>
        <DataGridPro
          columns={columns ? columns : []}
          rows={data ? data : []}
          rowCount={data ? data?.data?.count : 0}
          // onRowClick={(params) => openEditDialogue(params.row)}
          sortModel={sortModel}
          pageSize={pageSize}
          rowsPerPageOptions={[100, 500, 5000]}
          onPageSizeChange={handlePageSizeChange}
          checkboxSelection
          disableSelectionOnClick
          columnVisibilityModel={{...columnVisibilityModel}}
          onColumnVisibilityModelChange={(model) =>
            setColumnVisibilityModel(model)
          }
          getRowId={page === "reports" ? reportId : defaultId}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
          }}
          experimentalFeatures={{aggregation: true}}
          loading={loading}
          pagination
          page={pageNumber}
          onPageChange={handlePageChange}
          components={{Toolbar: CustomToolbar}}
          componentsProps={{
            toolbar: {
              openEditDialogue,
              setOpenReportGen,
              page,
              selected,
              open,
              handleClickOpen,
              setOpenUnknownDealUpload,
              setOpenDealExport,
              selectedRows
            },
          }}
          selectionModel={entries}
          onSelectionModelChange={(model) => {
            selectedRowsForAssetAndUnknowAsset(model);
            setEntries(model);
            console.log(model);
            const selectedData = data.filter((row) =>
              model.includes(`${row.deal}${row.quarter}${row.year}`)
            );
            setSelected(model);

            const ReportListFilter = selectedData.map((item) => {
              return {
                deal_id: item.deal,
                deal_name:item.name,
                type: item.type,
                quarter: item.quarter,
                client: item.client,
                year: item.year,
                sent: item.sent,
                canSend: item.can_send,
              };
            });

            setReportList(ReportListFilter);

            const isSentcheck = ReportListFilter.every(
              (obj) => obj.sent === false
            );
            setCheckSent(isSentcheck);

            const unSentReports = ReportListFilter.filter(
              (item) => item.sent === false
            );
            setUnSent(unSentReports);

            const canSendEmails = ReportListFilter.filter(
              (item) => item.canSend === false
            );
            setCanSendEmails(canSendEmails);

            const sentReports = ReportListFilter.filter(
              (item) => item.sent === true
            );
            setSentReports(sentReports);
          }}
          isRowSelectable={(params) => {
            return params.row.status !== "Disabled";
          }}
        />
        <EditData
          open={openEdit}
          openEditDialogue={openEditDialogue}
          options={options}
          order={editOrder}
          rowToEdit={rowToEdit}
          isAdd={isAdd}
          page={defaultParams?.["edit_endpoint"] ?? page}
          setRefresh={setRefresh}
        />
        <UnknownDealUpload
          open={openUnknownDealUpload}   
          close={() => setOpenUnknownDealUpload(false)}       
        ></UnknownDealUpload>
        <ReportGen
          open={openReportGen}
          close={() => setOpenReportGen(false)}
          selected={selected}
          setRefresh={setRefresh}
        ></ReportGen>

        {checkSent ? (
          <Dialog
            open={open}
            fullWidth
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Report Email</DialogTitle>
            {canSendEmails.length > 0 && (
              <DialogContentText id="alert-dialog-description"
              textAlign="center"
              fontWeight="bold"
              sx={{
                paddingLeft: '10px',
                paddingRight: '10px',
              }}>
                The deal(s) listed below are currently marked as not to be sent to clients. Please review them and ensure only deals marked to be sent are selected.
                <Stack direction="row" spacing={2} justifyContent="center">
                  <List id="alert-dialog-description" textAlign="center" fontWeight="bold">
                    {canSendEmails.map((report) => (
                      <ListItem key={`${report.deal_id}${report.quarter}${report.year}`}>
                        <ListItemText primary={`${report.deal_id} - Q${report.quarter} ${report.year}`}></ListItemText> 
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              </DialogContentText>
            )}
            {canSendEmails.length === 0 && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description" >
                Are you sure, You want to send the reports?
              </DialogContentText>
            </DialogContent>
            )}
            {canSendEmails.length === 0 && (
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={handleSendReport} autoFocus disabled={canSendEmails.length > 0}>
                Yes
              </Button>
            </DialogActions>
            )}
            {canSendEmails.length > 0 && (
            <DialogActions>
              <Button onClick={handleClose}>OK</Button>
            </DialogActions>
            )}
          </Dialog>
        ) : (
          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Report Email</DialogTitle>

            {canSendEmails.length > 0 && (
              <DialogContentText id="alert-dialog-description"
              textAlign="center"
              fontWeight="bold"
              sx={{
                paddingLeft: '10px',
                paddingRight: '10px',
              }}>
                The deal(s) listed below are currently marked as not to be sent to clients. Please review them and ensure only deals marked to be sent are selected.
                <Stack direction="row" spacing={2} justifyContent="center">
                  <List id="alert-dialog-description" textAlign="center" fontWeight="bold">
                    {canSendEmails.map((report) => (
                      <ListItem key={`${report.deal_id}${report.quarter}${report.year}`}>
                        <ListItemText primary={`${report.deal_id} - Q${report.quarter} ${report.year}`}></ListItemText> 
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              </DialogContentText>
            )}
            <Divider />
            {canSendEmails.length === 0 && (
            <DialogContentText
              id="alert-dialog-description"
              textAlign="center"
              fontWeight="bold"
            >
              {sentReports.length} Deal have already sent their reports.
            </DialogContentText>
            )}
            <Divider />
            {canSendEmails.length === 0 && (
            <DialogContent>
              <Stack direction="row" spacing={2} justifyContent="center">
                <List>
                  <DialogContentText id="alert-dialog-description">
                    Already sent Reports
                  </DialogContentText>
                  {sentReports.map((report) => {
                    return (
                      <ListItem
                        key={`${report.deal_id}${report.quarter}${report.year}`}
                      >
                        <ListItemText primary={report.deal_id}></ListItemText>
                      </ListItem>
                    );
                  })}
                </List>

                <Divider orientation="vertical" flexItem />

                <List>
                  <DialogContentText id="alert-dialog-description">
                    Unsent Reports
                  </DialogContentText>

                  {unSent.map((report) => {
                    return (
                      <ListItem
                        key={`${report.deal_id}${report.quarter}${report.year}`}
                      >
                        <ListItemText primary={report.deal_id}></ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Stack>
            </DialogContent>
            )}
            {canSendEmails.length === 0 && (
            <DialogActions>
              <Button onClick={handleClose}>Cancle</Button>
              {/* disabled={canSendEmails.length > 0} */}
              <Button onClick={handleSendReport} autoFocus disabled={canSendEmails.length > 0}>
                Send All Reports
              </Button>
              <Button onClick={handleUnsentReports} autoFocus disabled={canSendEmails.length > 0}>
                Send Unsent Reports
              </Button>
            </DialogActions>
            )}
            {canSendEmails.length > 0 && (
            <DialogActions>
              <Button onClick={handleClose}>OK</Button>
            </DialogActions>
            )}
          </Dialog>
        )}
        <DealExport
          open={openDealExport}
          close={() => setOpenDealsProgrammeExport(false)}
          selectedRows={selectedRows}
          selectedColumns={columnVisibilityModel}
        ></DealExport>
      </Box>
    </>
  );
};

export default DealsPage;

// https://localhost:3000/deals?custom_id_stem=SOT
