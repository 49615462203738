import { Routes, Route } from "react-router-dom"
import MiniDrawer from "../navigation/navigation"

import PrivateRoutes from "./privateRoutes"
import LoginPage from "../pages/loginPage"
import ManagePage from '../pages/managePage'
import ManagePageBQ from '../pages/managePageBQ'
import { Main } from "../theme/theme"
import { Fragment } from "react"
import ClientsPage from '../pages/clientsPage'
import DealsPage from '../pages/dealsPage'
import ProgrammePage from '../pages/programmePage'
import ReportSharingPage from '../pages/reportSharingPage'
import ReportHistoryPage from '../pages/reportHistoryPage'
import ExchangeRatesPage from '../pages/exchangeRatesPage'
import FacebookPagesPage from '../pages/facebookPagesPage'
import PermissionPage from '../pages/permissionPage'
import AssetsPage from '../pages/assetsPage'
import DfpRatesPage from '../pages/dfpRatesPage'

const AppRoutes = ({ menuNavigationItems, open, setOpen }) => {

  return (
    <Routes>
      <Route exact path='/' element={<LoginPage />} />
      <Route element={<PrivateRoutes />}>
      <Route path="/*" element={
        <>
        <MiniDrawer menuNavigationItems={menuNavigationItems} open={open} setOpen={setOpen} />
        <Main open={open} >
          <Routes>
            {
            menuNavigationItems.map(({ sectionName, headers }) => (
              sectionName === "MANAGE" ?
              headers.map(({ pageLink, columnsToShow, defaultParams, bigQueryTable, CustomFilter, customColumns }) => 
                <Route key={pageLink} path={pageLink} element={
                pageLink === "clients" ?
                <ClientsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "deals" ? 
                <DealsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "assets" ?
                <AssetsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter}/>
                : pageLink === "dfp_rates" ?
                <DfpRatesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter}/>
                : pageLink === "programmes" ?
                <ProgrammePage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "reports" ?
                <ReportSharingPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "job" ?
                <ReportHistoryPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "exchange_rates" ?
                <ExchangeRatesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "channels" ?
                <FacebookPagesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "users" ?
                <PermissionPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : 
                <ManagePage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                } />)
              : sectionName === "ACCESS" ?
              headers.map(({ pageLink, columnsToShow, defaultParams, bigQueryTable, CustomFilter, customColumns }) => 
                <Route key={pageLink} path={pageLink} element={
                pageLink === "clients" ?
                <ClientsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "deals" ? 
                <DealsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "programmes" ?
                <ProgrammePage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "assets" ?
                <AssetsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter}/>
                : pageLink === "dfp_rates" ?
                <DfpRatesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter}/>
                : pageLink === "reports" ?
                <ReportSharingPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "job" ?
                <ReportHistoryPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "exchange_rates" ?
                <ExchangeRatesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "channels" ?
                <FacebookPagesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "users" ?
                <PermissionPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                :
                <ManagePage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                } />)   
              : sectionName === "REPORT" ?
              headers.map(({ pageLink, columnsToShow, defaultParams, bigQueryTable, CustomFilter, customColumns }) => 
                <Route key={pageLink} path={pageLink} element={
                pageLink === "clients" ?
                <ClientsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} customColumns={customColumns}/>
                :  pageLink === "deals" ? 
                <DealsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} customColumns={customColumns} />
                : pageLink === "programmes" ?
                <ProgrammePage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} customColumns={customColumns}/>
                : pageLink === "assets" ?
                <AssetsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter}/>
                : pageLink === "dfp_rates" ?
                <DfpRatesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter}/>
                : pageLink === "reports" ?
                <ReportSharingPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} customColumns={customColumns}/>
                : pageLink === "job" ?
                <ReportHistoryPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} customColumns={customColumns}/>
                : pageLink === "exchange_rates" ?
                <ExchangeRatesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} customColumns={customColumns}/>
                : pageLink === "channels" ?
                <FacebookPagesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} customColumns={customColumns}/>
                : pageLink === "users" ?
                <PermissionPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} customColumns={customColumns}/>
                :
                <ManagePage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} customColumns={customColumns}/>
                } />)
              : sectionName === "GOOGLE SHEETS" ?
              headers.map(({ pageLink, columnsToShow, defaultParams, bigQueryTable, CustomFilter }) => 
                <Route key={pageLink} path={pageLink} element={
                pageLink === "clients" ?
                <ClientsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                :  pageLink === "deals" ? 
                <DealsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams}/>
                : pageLink === "programmes" ?
                <ProgrammePage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "assets" ?
                <AssetsPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter}/>
                : pageLink === "dfp_rates" ?
                <DfpRatesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} CustomFilter={CustomFilter}/>
                : pageLink === "reports" ?
                <ReportSharingPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "job" ?
                <ReportHistoryPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "exchange_rates" ?
                <ExchangeRatesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "channels" ?
                <FacebookPagesPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                : pageLink === "users" ?
                <PermissionPage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                :
                <ManagePage page={pageLink} columnsToShow={columnsToShow} defaultParams={defaultParams} />
                } />)
              :
              <Fragment key={sectionName} ></Fragment>
            ))
            }
          </Routes>
        </Main>
        </>
      } />
      </Route>
  </Routes>
  )
}

export default AppRoutes
