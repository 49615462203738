import {useEffect, useState} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import sendRequest from "../services/sendRequest";
import getOptions from "../services/getOptions";
import EditData from "../components/editData";
import CustomToolbar from "../components/customToolbar";
import UnknownProgrammeUpload from "../components/unknownProgrammeUpload";
import ProgrammeExport from "../components/programmeExport";
import ProgrammeDelete from "../components/ProgrammeDelete";

const ProgrammePage = ({
  page,
  columnsToShow,
  defaultParams,
  customColumns = {},
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams({});
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editOrder, setEditOrder] = useState([]);
  const [columns, setColumns] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState();
  const [options, setOptions] = useState({});
  const [rowToEdit, setRowToEdit] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  
  const [openUnknownProgrammeUpload, setOpenUnknownProgrammeUpload] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [progress, setProgress] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [openEdit, setOpenEdit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [entries, setEntries] = useState([]);
  const [time, setTime] = useState(0);  
  const [programmeFilters, setProgrammeFilters] = useState({items: []});
  let [programmeDataLoadFully, setProgrammeDataLoadFully] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: page === "job" ? "desc" : "asc",
    },
  ]);
  const [openProgrammeDelete, setOpenProgrammeDelete] = useState(false);
  const [openProgrammeExport, setOpenProgrammeExport] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const role=localStorage.getItem("role")
  console.log("ProgrammePage",page)
  console.log("ProgrammePage",data)
  localStorage.setItem('page',page)
  if (page === "deals") {
    if (data.length > 0) {
      const bulkDeals = localStorage.getItem('bulk_deals');
      if (bulkDeals) {
        const bulkDealsArray = JSON.parse(bulkDeals);
        if (bulkDealsArray.length > 0) {
          setData([...data, ...bulkDealsArray])
        }
      }
    }
    localStorage.setItem('bulk_deals',[]);
  }
  console.log("pagepagepagepage",data)  
  if (page === "programmes") {
    const storedArray = localStorage.getItem('deleted_programme_custom_ids');
    console.log(storedArray);
    if (storedArray && storedArray !== "") {
      const idsToRemove = storedArray.split(',');
      const newArray = [...data];
      for (let i = 0; i < idsToRemove.length; i++) {
        for (let j = 0; j < newArray.length; j++) {
          if (idsToRemove[i] === newArray[j].custom_id) {
            newArray.splice(j, 1);
            j--;
          }
        }
      } 
      console.log(newArray.length)
      setData(newArray)
      localStorage.setItem('deleted_programme_custom_ids', "");
    }
  }

  const setOpenDealsProgrammeExport = () => {
    setOpenProgrammeExport(false)
    setSelectedRows([]);
    setOpenProgrammeDelete(false)
    setSelected([])
    setEntries([]);
  };

  const setOpenProgrammsDelete = () => {
    setOpenProgrammeDelete(false)
    setSelectedRows([]);
    setSelected([])
    setEntries([]);
  };


  useEffect(() => {
    localStorage.setItem('programmes_filter', JSON.stringify(false));
    console.log("working...........")
    let fetched = false;
    async function getData() {
      setLoading(true);
      if(page==="users")
        await getOptions("permission");
      const metadata = await getOptions(page);
      console.log("metadatametadatametadata",metadata)
      if(page === "programmes"){
        delete metadata.series;
        await getOptions("deals_data");
       let deal_new_Data=JSON.parse(localStorage.getItem('deal_data'))
        if(deal_new_Data!=null && deal_new_Data!=undefined)
           metadata.deal.options=deal_new_Data
      }
      if (!metadata) {
        enqueueSnackbar("Failed to load Data Options!", {variant: "error"});
        return;
      }
      console.log(metadata);
      setOptions(metadata);

      const cols = await createColumns(metadata);
      if(page === "programmes"){
        for (let i = 0; i < cols.length; i++) {
          if(cols[i].field === "programme_rules"){
            delete cols[i];
          }
        }
      }
      setColumns(cols);

      const cvm = await createColumnVisibilityModel(metadata);
      setColumnVisibilityModel(cvm ?? false);

      const notReadOnly = Object.entries(metadata)
        .filter(([_, v]) => !v.read_only)
        .map(([k, _]) => k);
      setEditOrder(notReadOnly);
      let response=[];
      if (page === "programmes") {
        let newData = [];
        let pageNumber = 0;
        
        try {
          do {
              let endpoint = "";
              if(pageNumber === 0) {
                endpoint = `${page}/get_programmes/?offset=${0}&limit=2500`
              } else if(pageNumber === 1){
                endpoint = `${page}/get_programmes/?offset=${2500}&limit=47500`;
              } else { 
                endpoint = `${page}/get_programmes/?offset=${(pageNumber-1) * 50000}&limit=50000`;
              }
              response = await sendRequest({
                requestType: "get",
                endpoint: endpoint,
              });
              setLoading(false);
              
              if (response.data && response.data && response.data.result.length > 0) {
                const flag = JSON.parse(localStorage.getItem('programmes_filter'));
                const search = JSON.parse(localStorage.getItem('programmes_search'));
                if(!flag && !search){
                  newData = [...newData, ...response.data.result];
                  setData(newData)
                  pageNumber++;        
                }
               
              }
             
            } while (response.data && response.data && response.data.result.length > 0); // Continue fetching until response count becomes 0
            setProgrammeDataLoadFully(true);
            enqueueSnackbar(`Data Load completed`, {variant: "success"});
          } catch (error) {
            console.error("An error occurred:", error);
          }
        
      }
    }

    if (fetched) return;
    getData().finally(() => setLoading(false));
    return () => (fetched = true);
  }, [page, refresh]);

  const createColumnVisibilityModel = async (ops) => {
    const model = {};
    Object.keys(ops).forEach((columnName) => {
      if (columnsToShow && !columnsToShow.includes(columnName))
        model[columnName] = false;
    });
    return model;
  };
  const handleClick = async () => { 
    const filterDetails = programmeFilters.items;
    localStorage.setItem('programmes_filter', JSON.stringify(true));
    let endpoint = ""; 
    let query = ""; 
    for (let i = 0; i < filterDetails.length; i++) {
      if(filterDetails[i].columnField === "deal") {
        if(i === 0) {
          query = "where "
        } else {
          query = query + " and "
        }
        query = query +" d.name "
        if(filterDetails[i].operatorValue === "contains") {
          query = query +"LIKE (" + "'"+"%"+filterDetails[i].value+"%')";
        }
        if(filterDetails[i].operatorValue === "equals") {
          query = query +"= '" + filterDetails[i].value+"'";
        }
        if(filterDetails[i].operatorValue === "startsWith") {
          query = query +"LIKE (" + "'"+filterDetails[i].value+"%')";
        }
        if(filterDetails[i].operatorValue === "endsWith") {
          query = query +"LIKE (" + "'%"+filterDetails[i].value+"')";
        }
        if(filterDetails[i].operatorValue === "isEmpty") {
          query = query +"is null";
        }
        if(filterDetails[i].operatorValue === "isNotEmpty") {
          query = query +"is not null";
        }
        if(filterDetails[i].operatorValue === "isAnyOf") {
          query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
        }
      }
      if(filterDetails[i].columnField === "custom_id") {
        if(i === 0) {
          query = "where "
        } else {
          query = query + " and "
        }
        query = query +" custom_id "
        if(filterDetails[i].operatorValue === "contains") {
          query = query +"LIKE (" + "'"+"%"+filterDetails[i].value+"%')";
        }
        if(filterDetails[i].operatorValue === "equals") {
          query = query +"= '" + filterDetails[i].value+"'";
        }
        if(filterDetails[i].operatorValue === "startsWith") {
          query = query +"LIKE (" + "'"+filterDetails[i].value+"%')";
        }
        if(filterDetails[i].operatorValue === "endsWith") {
          query = query +"LIKE (" + "'%"+filterDetails[i].value+"')";
        }
        if(filterDetails[i].operatorValue === "isEmpty") {
          query = query +"is null";
        }
        if(filterDetails[i].operatorValue === "isNotEmpty") {
          query = query +"is not null";
        }
        if(filterDetails[i].operatorValue === "isAnyOf") {
          query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
        }
       
      }
      if(filterDetails[i].columnField === "title") {
        if(i === 0) {
          query = "where "
        } else {
          query = query + " and "
        }
        query = query +" title "
        if(filterDetails[i].operatorValue === "contains") {
          query = query +"LIKE (" + "'"+"%"+filterDetails[i].value+"%')";
        }
        if(filterDetails[i].operatorValue === "equals") {
          query = query +"= '" + filterDetails[i].value+"'";
        }
        if(filterDetails[i].operatorValue === "startsWith") {
          query = query +"LIKE (" + "'"+filterDetails[i].value+"%')";
        }
        if(filterDetails[i].operatorValue === "endsWith") {
          query = query +"LIKE (" + "'%"+filterDetails[i].value+"')";
        }
        if(filterDetails[i].operatorValue === "isEmpty") {
          query = query +"is null";
        }
        if(filterDetails[i].operatorValue === "isNotEmpty") {
          query = query +"is not null";
        }
        if(filterDetails[i].operatorValue === "isAnyOf") {
          query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
        }
      }     
    }
    let response=[];
    let encodedQuery = encodeURIComponent(query); 
    let newData = [];
    let pageNumber = 0;
    try {
      do {
          let endpoint = "";
          if(pageNumber === 0) {
            endpoint = `${page}/get_programmes/?offset=${0}&limit=2500&filters=${encodedQuery}`
          } else if(pageNumber === 1){
            endpoint = `${page}/get_programmes/?offset=${2500}&limit=47500&filters=${encodedQuery}`;
          } else { 
            endpoint = `${page}/get_programmes/?offset=${(pageNumber-1) * 50000}&limit=50000&filters=${encodedQuery}`;
          }
          response = await sendRequest({
            requestType: "get",
            endpoint: endpoint,
          });
          if(pageNumber === 0) {
            setLoading(false);
          }
          
          if (response.data && response.data && response.data.result.length > 0) {
            newData = [...newData, ...response.data.result];
            const flag = JSON.parse(localStorage.getItem('programmes_filter'));
                if(flag) {
                  setData(newData)
                 
                  pageNumber++;
                }  
          }
      } while (response.data && response.data && response.data.result.length > 250); // Continue fetching until response count becomes 0 
        enqueueSnackbar(`Data Load completed`, {variant: "success"});
      } catch (error) {
        console.error("An error occurred:", error);
      }    
  };

  const createColumns = async (ops) => [
    ...Object.keys(ops).map((header) => {
      let currentCol = {
        field: header,
        headerName: ops[header].label,
        flex: 1,
      };

      if (header in customColumns) {
        currentCol = {...currentCol, ...customColumns[header]};
      }
      return currentCol;
    }),
    ...(page === "job"
      ? [
          {
            field: "",
            headerName: "View",
            width: 150,

            renderCell: ({row}) => (
              <Button onClick={() => viewJob(row)}>View</Button>
            ),
          },
        ]
      : page === "quarterly_report" || role != "full access"
      ? []
      : [
          {
            field: "",
            headerName: "Edit",
            width: 150,
            filterable: false,
            renderCell: ({row}) => (
              <EditIcon onClick={() => openEditDialogue(row)} />
            ),
          },
        ]),
  ];

  const viewJob = (job) => {
    if (job?.id) {
      navigate(`/task?job=${job.id}`);
    } else {
      enqueueSnackbar("Invalid Job ID", {variant: "warning"});
    }
  };

  const openEditDialogue = async (rowToEdit) => {
    let entity_id = null;
    if (!rowToEdit) {
      setOpenEdit(false);
    } else if (rowToEdit === "add") {
      setOpenEdit(true);
      setIsAdd(true);
      setRowToEdit(rowToEdit);
    } else {
      if (page === "programmes") {
        localStorage.setItem('entity_id', rowToEdit.custom_id)
        localStorage.setItem('seriesForCompare', JSON.stringify(JSON.parse(rowToEdit.series)));
        entity_id = rowToEdit.custom_id;
      } else {
        localStorage.setItem('entity_id', null) 
      }
      const auditPage = 'auditprogramme'
      await sendRequest({
        requestType: "get",
        endpoint: `${auditPage}/?entity_id=${entity_id}&page=${page}`
      }).then(response => {
        const dummyData = Array.isArray(response) ? response : response.data;
        console.log(dummyData);
        localStorage.setItem('audit', JSON.stringify(dummyData));
      
        // Now you can safely use map
        dummyData.map((row, index) => {
          // Your mapping logic
        });
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
      setOpenEdit(true);
      setIsAdd(false);
      setRowToEdit(rowToEdit);
    }
  };

  const selectedRowsForProgrammeExport = (model) => {
    if(page === 'programmes') {
      const seledtedRows = data.filter((x) => model.some((y) => y === x.custom_id));
      setSelectedRows(seledtedRows)
    }
  };
  
  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSizeNumber) => {
    setPageSize(pageSizeNumber);
  };

  if (!columns) {
    return null;
  }


  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFilterChange = (filterModel) => {

    if(!programmeDataLoadFully) {
      if(page ==='programmes') {
        if(filterModel.quickFilterValues !=null && filterModel.quickFilterValues[0] !== undefined){
          setLoading(true)
          let search_data=''
          for (let i = 0; i < filterModel.quickFilterValues.length; i++) {
            if(filterModel.quickFilterValues.length-1===i){
              search_data =search_data + filterModel.quickFilterValues[i]
            }else{
              search_data =search_data + filterModel.quickFilterValues[i]+" "
            }
            
          }
          localStorage.setItem('programmes_search', JSON.stringify(true));
          handleQuickFilter(search_data)
        }else{
          setLoading(true)
          localStorage.setItem('programmes_search', JSON.stringify(false));
        }
        if(filterModel.items.length == 0){
          localStorage.setItem('programmes_filter', JSON.stringify(false));
        }
        setProgrammeFilters(filterModel);
      } 
    }else{
      if(page ==='programmes') {
        if(filterModel.items.length == 0){
         
          localStorage.setItem('programmes_filter', JSON.stringify(false));
        }
        setProgrammeFilters(filterModel);
      } 
    }
    
  }
  const handleQuickFilter = async (search_data) => {
      let newData = [];
      let query = "where custom_id ILIKE '%"+search_data+"%' or title ILIKE '%"+search_data+"%'"
      let encodedQuery = encodeURIComponent(query);
      const endpoint = `${page}/get_programmes/?offset=${0}&limit=2500&filters=${encodedQuery}`
      const response = await sendRequest({
        requestType: "get",
        endpoint: endpoint,
      });
      setLoading(false);
      if (response.data && response.data && response.data.result.length > 0) {
       newData = [...newData, ...response.data.result];
       const flag = JSON.parse(localStorage.getItem('programmes_filter'));
       setData(newData)           
      }  
  };
  const reportId = (row) => `${row.deal}${row.quarter}${row.year}`;
  //const uniqId = (row) => `${row.year}` + Math.random();

  const defaultId = (row) => {
    if (page === "exchange_rates") {
      return row.id + 1;
    }
    if (defaultParams && "row_id" in defaultParams)
      return defaultParams["row_id"];
    return row.id
      ? row.id
      : row.custom_id_stem
      ? row.custom_id_stem
      : row.custom_id;
  };
  return (
    <>

      <Box sx={{height: "100%", width: "100%"}}>
       
        {page === 'programmes' && programmeFilters.items.length > 0 && programmeDataLoadFully === false ? (
          <Button disabled={programmeDataLoadFully} onClick={handleClick}>Apply Filter</Button>
          ):(
          <p></p>
        )} 
        <DataGridPro
          columns={columns ? columns : []}
          rows={data ? data : []}
          rowCount={data ? data?.data?.count : 0}
          // onRowClick={(params) => openEditDialogue(params.row)}
          sortModel={sortModel}
          pageSize={pageSize}
          rowsPerPageOptions={[100, 500, 5000]}
          onPageSizeChange={handlePageSizeChange}
          checkboxSelection
          disableSelectionOnClick
          columnVisibilityModel={{...columnVisibilityModel}}
          onColumnVisibilityModelChange={(model) =>
            setColumnVisibilityModel(model)
          }
          getRowId={page === "reports" ? reportId : defaultId}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
          }}
          experimentalFeatures={{aggregation: true}}
          onFilterModelChange={handleFilterChange}
          loading={loading}
          pagination
          page={pageNumber}
          onPageChange={handlePageChange}
          components={{Toolbar: CustomToolbar}}
          componentsProps={{
            toolbar: {
              openEditDialogue,
              page,
              selected,
              open,
              handleClickOpen,
              setOpenUnknownProgrammeUpload,
              setOpenProgrammeExport,              
              setOpenProgrammeDelete,
              selectedRows
            },
          }}
          selectionModel={entries}
          onSelectionModelChange={(model) => {
            setEntries(model);
            console.log(model);
            selectedRowsForProgrammeExport(model);
          }}
          isRowSelectable={(params) => {
            return params.row.status !== "Disabled";
          }}
        />
        <EditData
          open={openEdit}
          openEditDialogue={openEditDialogue}
          options={options}
          order={editOrder}
          rowToEdit={rowToEdit}
          isAdd={isAdd}
          page={defaultParams?.["edit_endpoint"] ?? page}
          setRefresh={setRefresh}
        />
        <UnknownProgrammeUpload
          open={openUnknownProgrammeUpload}   
          close={() => setOpenUnknownProgrammeUpload(false)}       
        ></UnknownProgrammeUpload>
        <ProgrammeExport
          open={openProgrammeExport}
          close={() => setOpenDealsProgrammeExport(false)}
          selectedRows={selectedRows}
          selectedColumns={columnVisibilityModel}
        ></ProgrammeExport>
        <ProgrammeDelete
          open={openProgrammeDelete}
          close={() => setOpenProgrammsDelete(false)}
          selectedRows={selectedRows}
          selectedColumns={columnVisibilityModel}
        ></ProgrammeDelete>
      </Box>
    </>
  );
};

export default ProgrammePage;
