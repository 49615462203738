import {useNavigate} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import * as XLSX from 'xlsx';
import sendRequest from "../services/sendRequest";
import MoonLoader from "react-spinners/MoonLoader";

const UnknowAssetsUpload =({open, close, selected, setRefresh}) => {

const { enqueueSnackbar } = useSnackbar()
const [items, setItems] = useState([]);
const [formData, setFormData] = useState({});
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth, setMaxWidth] = React.useState('sm');
let [loading, setLoading] = useState(false);
let [color, setColor] = useState("#000000");
const page ="assets";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

  const handleClose = () => {
      close();
  };

  useEffect(() => {
  });

  // handle file upload
  const handleFileUpload = e => {
    const numberOfColumns = 2 
    if (numberOfColumns == 2) {
      try {
        setLoading(true); // Indicate loading state
        const file = e.target.files[0]; // Get the uploaded file
        if (!file) {
          console.error("No file selected");
          setLoading(false);
          return;
        }
        const reader = new FileReader();
        reader.onload = async (event) => {
          const fileContent = event.target.result; // Get the file content
          // Split the content into lines
          const lines = fileContent.split('\n');
          // Initialize an array to store the objects
          const items = [];
          let tempRecord = null;
          for (let line of lines) {
            // Trim whitespace from the line
            line = line.trim();
            if (!line) continue; // Skip empty lines
            // Check if the line is incomplete (e.g., starts with a quote but doesn't end with one)
            if (line.startsWith('"') && !line.endsWith('"')) {
              tempRecord = line; // Start collecting the incomplete record
              continue;
            }
            if (tempRecord) {
              // Combine the incomplete record with the current line
              line = tempRecord + line;
              tempRecord = null; // Reset tempRecord
            }
            // Split the line using a comma as the delimiter
            const parts = line.split(',');
            // Remove quotes from the fields
            const asset_id = parts[0]?.replace(/"/g, '')?.trim();
            const custom_id = parts[1]?.replace(/"/g, '')?.trim();
            if (asset_id && custom_id) {
              // Create an object for the row
              const item = { asset_id, custom_id };
              items.push(item);
            } else {
              console.warn(`Skipping malformed line: ${line}`);
            }
          }
          // Send the data to the API
          const response = await sendRequest({
            requestType: "put",
            endpoint: `${page}?fileupload=${"yes"}`,
            requestData: items,
          });
          console.log('Response:', response);
          if (response.status === 200) {
            setItems([]);
            handleSuccess();
            handleClose();
            setLoading(false);
          } else {
            handleError();
            setLoading(false);
          } 
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          setLoading(false);
        };
        reader.readAsText(file); // Read the file as text           
      } catch (error) {
        console.error("An error occurred:", error);
        handleError();
        setLoading(false);
      }
    } else {
      setItems([]);
      handleErrorForMoreThanTwoCoulumnsInFile();
      handleClose();
    }
  }

  const handleError = () => {
    enqueueSnackbar('There is an Error with the file, Please Check the .csv file and Reupload it!', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleErrorForMoreThanTwoCoulumnsInFile = () => {
    enqueueSnackbar('The file should contains only 2 Columns [Asset ID and Custom ID] with no headers', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 10000,
    });
  };

  const handleSuccess = () => {
    enqueueSnackbar('The Data updated Successfully!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 3000,
    });
  };
  return (
    <Dialog
    open={open}
    onClose={() => {
      close();
    }}
    >
      <DialogContent>
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
          >
            <MoonLoader
              color={color}
              loading={loading}
              cssOverride={override}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <DialogTitle sx={{ textAlign: 'center' }}>Upload CSV</DialogTitle>
            <DialogContent sx={{ marginBottom: 2 }}>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
                Upload a CSV file to bulk update Asset ID and Custom ID values:
              </DialogContentText>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
                The .CSV file should contains only 2 Columns 1)Asset ID 2)Custom ID with no headers
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >        
              <FormControl sx={{ mt: 2, minWidth: 120 }}>              
                <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload}/>
              </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default UnknowAssetsUpload;
