import {useEffect, useState} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Box, Button} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import sendRequest from "../services/sendRequest";
import getOptions from "../services/getOptions";
import EditData from "../components/editData";
import CustomToolbar from "../components/customToolbar";
import UserDelete from "../components/userDelete";
const PermissionPage = ({
  page,
  columnsToShow,
  defaultParams,
  customColumns = {},
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams({});
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editOrder, setEditOrder] = useState([]);
  const [columns, setColumns] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState();
  const [options, setOptions] = useState({});
  const [rowToEdit, setRowToEdit] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [progress, setProgress] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [openEdit, setOpenEdit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [entries, setEntries] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: page === "job" ? "desc" : "asc",
    },
  ]);
  const [openUserDelete, setOpenUserDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const role=localStorage.getItem("role")
  console.log("PermissionPage",page)
  console.log("PermissionPage",data)
  localStorage.setItem('page',page)
  console.log("pagepagepagepage",data)
  if (page === "users") {
    const storedArray = localStorage.getItem('deleted_user_ids');
    console.log(storedArray);
    if (storedArray && storedArray !== "") {
      const idsToRemove = storedArray.split(',');
      const newArray = [...data];
      // const newData = dataU.filter(obj => !idsToRemove.has(obj.id));
      for (let i = 0; i < idsToRemove.length; i++) {
        // Iterate over the second array  
        for (let j = 0; j < newArray.length; j++) {
          // Check if the IDs match
          if (parseInt(idsToRemove[i]) === newArray[j].id) {
            // Remove the element from the first array
            newArray.splice(j, 1);
            j--;
          }
        }
      } 
      console.log(newArray.length)
      setData(newArray)
      localStorage.setItem('deleted_user_ids', "");
    }
  }
  
const setOpenUsersDelete = () => {
    setOpenUserDelete(false)
    setSelectedRows([]);
    setSelected([])
    setEntries([]);
  };

  
  useEffect(() => {
    localStorage.setItem('programmes_filter', JSON.stringify(false));
    console.log("working...........")
    let fetched = false;
    async function getData() {
      setLoading(true);
      if(page==="users")
        await getOptions("permission");
      const metadata = await getOptions(page);
      console.log("metadatametadatametadata",metadata)
      if (!metadata) {
        enqueueSnackbar("Failed to load Data Options!", {variant: "error"});
        return;
      }
      console.log(metadata);
      setOptions(metadata);

      const cols = await createColumns(metadata);
      setColumns(cols);

      const cvm = await createColumnVisibilityModel(metadata);
      setColumnVisibilityModel(cvm ?? false);

      const notReadOnly = Object.entries(metadata)
        .filter(([_, v]) => !v.read_only)
        .map(([k, _]) => k);
      setEditOrder(notReadOnly);
      let response=[];      
      response = await sendRequest({
        requestType: "get",
        endpoint: `${page}/?${
          page === "task" ? "latest=true&" : ""
        }${searchParams.toString()}`,
      });

      console.log(response);

      if (
        page === "task" &&
        response.data.some(
          (item) => item.status === "running" || item.status === "queued"
        )
      ) {
        setRefresh((oldState) => !oldState);
      }
      if (response.code) {
        enqueueSnackbar("Failed to load Data!", {variant: "error"});
      } else {
        if(page==="users")
            response.data.forEach(user => {
              const data1 = data.find(roleUser => roleUser.id === user.id);
              if (data1) {
                user.role = data1.role;  // Add the role if found
              } else {
                user.role = 'default';  // Assign a default role if not found
              }
            });
        console.log(response);
        if (page != "programmes") {
          setData(response.data);
        }
      }
    }

    if (fetched) return;
    getData().finally(() => setLoading(false));
    return () => (fetched = true);
  }, [page, refresh]);

  const createColumnVisibilityModel = async (ops) => {
    const model = {};
    Object.keys(ops).forEach((columnName) => {
      if (columnsToShow && !columnsToShow.includes(columnName))
        model[columnName] = false;
    });
    return model;
  };

  const createColumns = async (ops) => [
    ...Object.keys(ops).map((header) => {
      let currentCol = {
        field: header,
        headerName: ops[header].label,
        flex: 1,
        // filterOperators: getGridStringOperators().filter(
        //   (operator) => operator.value === "equals"
        // ),
      };

      if (header in customColumns) {
        currentCol = {...currentCol, ...customColumns[header]};
      }
      return currentCol;
    }),
    ...(page === "job"
      ? [
          {
            field: "",
            headerName: "View",
            width: 150,

            renderCell: ({row}) => (
              <Button onClick={() => viewJob(row)}>View</Button>
            ),
          },
        ]
      : page === "quarterly_report" || role != "full access"
      ? []
      : [
          {
            field: "",
            headerName: "Edit",
            width: 150,
            filterable: false,
            renderCell: ({row}) => (
              <EditIcon onClick={() => openEditDialogue(row)} />
            ),
          },
        ]),
  ];

  const viewJob = (job) => {
    if (job?.id) {
      navigate(`/task?job=${job.id}`);
    } else {
      enqueueSnackbar("Invalid Job ID", {variant: "warning"});
    }
  };

  const openEditDialogue = async (rowToEdit) => {
    let entity_id = null;
    if (!rowToEdit) {
      setOpenEdit(false);
    } else if (rowToEdit === "add") {
      setOpenEdit(true);
      setIsAdd(true);
      setRowToEdit(rowToEdit);
    } else {
        localStorage.setItem('entity_id', null) 
    }
  };

  const selectedRowsForUserDelete = (model) => {
    if(page === 'users') {
      const seledtedRows = data.filter((x) => model.some((y) => y === x.id));
      setSelectedRows(seledtedRows)
    }
  };  

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSizeNumber) => {
    setPageSize(pageSizeNumber);
  };

  if (!columns) {
    return null;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const defaultId = (row) => {
    if (defaultParams && "row_id" in defaultParams)
      return defaultParams["row_id"];
    return row.id
      ? row.id
      : row.custom_id_stem
      ? row.custom_id_stem
      : row.custom_id;
  };
  return (
    <>

      <Box sx={{height: "100%", width: "100%"}}>        
        <DataGridPro
          columns={columns ? columns : []}
          rows={data ? data : []}
          rowCount={data ? data?.data?.count : 0}
          // onRowClick={(params) => openEditDialogue(params.row)}
          sortModel={sortModel}
          pageSize={pageSize}
          rowsPerPageOptions={[100, 500, 5000]}
          onPageSizeChange={handlePageSizeChange}
          checkboxSelection
          disableSelectionOnClick
          columnVisibilityModel={{...columnVisibilityModel}}
          onColumnVisibilityModelChange={(model) =>
            setColumnVisibilityModel(model)
          }
          getRowId={defaultId}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
          }}
          experimentalFeatures={{aggregation: true}}
          loading={loading}
          pagination
          page={pageNumber}
          onPageChange={handlePageChange}
          components={{Toolbar: CustomToolbar}}
          componentsProps={{
            toolbar: {
              openEditDialogue,
              page,
              selected,
              open,
              handleClickOpen,
              setOpenUserDelete,
              selectedRows
            },
          }}
          selectionModel={entries}
          onSelectionModelChange={(model) => {
            setEntries(model);
            console.log(model);
            selectedRowsForUserDelete(model);
          }}
          isRowSelectable={(params) => {
            return params.row.status !== "Disabled";
          }}
        />
        <EditData
          open={openEdit}
          openEditDialogue={openEditDialogue}
          options={options}
          order={editOrder}
          rowToEdit={rowToEdit}
          isAdd={isAdd}
          page={defaultParams?.["edit_endpoint"] ?? page}
          setRefresh={setRefresh}
        />
        <UserDelete
          open={openUserDelete}
          close={() => setOpenUsersDelete(false)}
          selectedRows={selectedRows}
          selectedColumns={columnVisibilityModel}
        ></UserDelete>
      </Box>
    </>
  );
};

export default PermissionPage;

// https://localhost:3000/deals?custom_id_stem=SOT
