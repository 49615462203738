import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useSnackbar } from "notistack";
import sendRequest from "../services/sendRequest";
import {useEffect, useState} from "react";
import MoonLoader from "react-spinners/MoonLoader";

const page ="programmes";


const ProgrammeDelete =({open, close, selectedRows, selectedColumns}) => {
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#000000");
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  const newHeaders = [];
  const { enqueueSnackbar } = useSnackbar();

  if(open === true) {
      
    const handleDeleteAndClose = async () => {
      let custom_ids_list = [];
      for (let i = 0; i < selectedRows.length; i++) {
        setLoading(true);
        custom_ids_list.push(selectedRows[i].custom_id)
      }
      if(custom_ids_list.length > 0){
        const response = await sendRequest({
          requestType: "delete",
          endpoint: `${page}/custom_bulk_delete_programme/`,
          requestData: custom_ids_list,
        });
        if (
          response?.status === 202 ||
          response?.status === 200 ||
          response?.status === 204
        ) {
          handleSuccess(response.data.message);
        } else if(response?.response?.status === 404) {
          enqueueSnackbar(`Programme Not Found`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            autoHideDuration: 5000,
          });
        }
      }
      localStorage.setItem('deleted_programme_custom_ids', custom_ids_list);
      setLoading(false);
      handleClose();
    };

    const handleClose = () => {
      close();
    };

    const handleSuccess = (Message) => {  
      enqueueSnackbar(Message, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        autoHideDuration: 3000,
      });
    };
  
    return (
      <Dialog
      open={open}
      onClose={() => {
        close();
      }}
      >
        <DialogContent>
          <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  height: '300px',
                  width: '600px',
                },
              }}>
              <MoonLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <DialogTitle sx={{ textAlign: 'center' }}>Programme Delete</DialogTitle>
              <DialogContent sx={{ marginBottom: 2 }}>
                <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
                  Are you sure want to delete the Programmes in Bulk?
                </DialogContentText>
                <DialogActions sx={{ textAlign: 'center'}}>
                    <Button onClick={handleDeleteAndClose} class="dialog-success-button">Delete</Button>
                </DialogActions>
              </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} class="dialog-close-button">Cancle</Button>
                </DialogActions>
            </Dialog>
        </DialogContent>
      </Dialog>
    );
  } 
};

export default ProgrammeDelete;
