import { useEffect, useState, } from "react";
import { useSnackbar } from "notistack";
import {
  DataGridPro,
} from "@mui/x-data-grid-pro";
import { Box, Button } from "@mui/material";
import sendRequest from "../services/sendRequest";

import CustomToolbar from "../components/customToolbar";

import DFPRatesUpload from "../components/dfpRatesUpload"
import * as React from 'react';

import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
const DfpRatesPage = ({ page, columnsToShow, defaultParams, CustomFilter }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [columns, setColumns] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(2500);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [targetValue, setTargetValue] = useState("");
  const [open, setOpen] = React.useState(false);
  const [entries, setEntries] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  let [assetsDataLoadFully, setAssetsDataLoadFully] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedRatesType, setSelectedRatesType] = useState("GPS Rates");
  const [selectedResellerType, setSelectedResellerType] = useState("");
  const [openDFPRatesUpload, setOpenDFPRatesUpload] = useState(false);
  let [isReseller, setIsReseller] = useState(false);

  localStorage.setItem('page',page)
 
  useEffect(() => {
    (async () => {
      const response = await sendRequest({
        requestType: "options",
        endpoint: `gps_rates`,
      });

      console.log(response);
      if (response.code) {
        console.log(response);
      } else {
        
        const cols = createColumns(response?.data?.actions?.POST);
        console.log(cols);
        setColumns(cols);
        const cvm = createColumnVisibilityModel(response?.data?.actions?.POST);
        
        setColumnVisibilityModel(cvm);
      }
    })();
  }, []);

  

  useEffect(() => {
    

    (async () => {
      // setCancelLoop(false);
      setLoading(true);
      enqueueSnackbar("Fetching data", {variant: "info"});
      const endpoint =`gps_rates?year=${selectedYear}`
      try {
        const response = await sendRequest({  
          requestType: "get",
          endpoint: endpoint,
        });

        if (response.code) {
        console.log(response);
          enqueueSnackbar(`Fetch Failed: ${response.code}`, {variant: "error"});
        } else {
            setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar("Error fetching data", {variant: "error"});
      } finally {
        setLoading(false);
      }

    })();

    // Cleanup function to cancel previous loop when server filters change

  }, [ ]);

  const createColumnVisibilityModel = (ops) => {
    const model = {};
    Object.keys(ops).forEach((columnName) => {
      if (columnsToShow && !columnsToShow.includes(columnName))
        model[columnName] = false;
    });
    return model;
  };
  const yearsOptions = [2024,2025,2026];
  const dfpRatestypeOptions = [
    "GPS Rates",
    "LDS Rates",
    "Reseller Rates",
  ];
  const resellerTypeOptions = ["Channel 4", "Scroll Media", "Influent","Whistle", "Corus", "Fox Entertainment"];

  // Handlers for dropdown changes
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleRatesTypeChange = (event) => {
    setSelectedRatesType(event.target.value);
    if(event.target.value === "Reseller Rates"){
      setIsReseller(true)
    }else{
      setSelectedResellerType(''); 
      setIsReseller(false)
    }
  };

  const handleResellerTypeChange = (event) => {
    setSelectedResellerType(event.target.value);
    
  };

  const handleSubmit = (event) => { 
    setLoading(true);
    setFilterModel({ items: [] });
    (async () => { 
        if(selectedRatesType === "GPS Rates"){
           
            (async () => {
                const response = await sendRequest({
                  requestType: "options",
                  endpoint: `gps_rates?year=${selectedYear}`,
                });
          
                console.log(response);
                if (response.code) {
                  console.log(response);
                } else {
                  const cols = createColumns(response?.data?.actions?.POST);
                  console.log(cols);
                  setColumns(cols);
                  const cvm = createColumnVisibilityModel(response?.data?.actions?.POST);
                  setColumnVisibilityModel(cvm);
                }
              })();
            const endpoint = `gps_rates?year=${selectedYear}`; 
            const response = await sendRequest({ 
                requestType: "get", 
                endpoint: endpoint, 
            });
            setLoading(false); 
            console.log("Response:", response); 
    
            if (!response || response.error) { 
                console.error(response); 
                enqueueSnackbar(`Fetch Failed: ${response.code}`, {variant: "error"});
            } else { 
                setData(response.data); 
            } 
        }else if(selectedRatesType === "LDS Rates"){
           
          (async () => {
              const response = await sendRequest({
                requestType: "options",
                endpoint: `lds_rates?year=${selectedYear}`,
              });
        
              console.log(response);
              if (response.code) {
                console.log(response);
              } else {
                // console.log(response?.data?.actions?.POST)
                const cols = createColumns(response?.data?.actions?.POST);
                console.log(cols);
                setColumns(cols);
                const cvm = createColumnVisibilityModel(response?.data?.actions?.POST);
                // setOptions(response?.data?.actions?.POST)
                setColumnVisibilityModel(cvm);
              }
            })();
          const endpoint = `lds_rates?year=${selectedYear}`; 
          const response = await sendRequest({ 
              requestType: "get", 
              endpoint: endpoint, 
          });
          setLoading(false); 
          console.log("Response:", response); 
  
          if (!response || response.error) { 
              console.error(response); 
              enqueueSnackbar(`Fetch Failed: ${response.code}`, {variant: "error"});
          } else { 
              setData(response.data); 
          } 
      }else{
            if(selectedResellerType!==""){
                (async () => {
                    const response = await sendRequest({
                      requestType: "options",
                      endpoint: `reseller_rates?year=${selectedYear}&resellers_name=${selectedResellerType}`,
                    });
              
                    console.log(response);
                    if (response.code) {
                      console.log(response);
                    } else {
                      // console.log(response?.data?.actions?.POST)
                      const cols = createColumns(response?.data?.actions?.POST);
                      console.log(cols);
                      setColumns(cols);
                      const cvm = createColumnVisibilityModel(response?.data?.actions?.POST);
                      // setOptions(response?.data?.actions?.POST)
                      setColumnVisibilityModel(cvm);
                    }
                  })();
                const endpoint = `reseller_rates?year=${selectedYear}&resellers_name=${selectedResellerType}`; 
                const response = await sendRequest({ 
                    requestType: "get", 
                    endpoint: endpoint, 
                });
                setLoading(false); 
                console.log("Response:", response); 
        
                if (!response || response.error) { 
                    console.error(response); 
                    enqueueSnackbar(`Fetch Failed: ${response.code}`, {variant: "error"});
                } else { 
                    setData(response.data); 
                }
            }else{
                setLoading(false); 
                enqueueSnackbar("Select Reseller type",{variant: "warning"});
            }

            
        }
        
    })(); // <-- Note the parentheses here to immediately invoke the async function
};

  const createColumns = (ops) => {
    console.log(ops);
      return Object.entries(ops).map(([k, v]) => ({
        id: k,
        field: k,
        headerName: v.label,
        flex: 1,
        filterable: true,
        hide: k === "id", // Hide the "id" column
      }));
  };
  console.log(data);
  const handleClearFilters = () => {
    setFilterModel(null); // Temporarily set to null to force a re-render
    setTimeout(() => {
      setFilterModel({ items: [] }); // Reset to an empty filter model
    }, 0);
  };
  
  if (!columns) {
    return null;
  }
  return (
    <> 
        <Box sx={{height: "92%", width: "100%"}}>
        <Box
            sx={{
              display: "flex", // Flexbox layout
              flexDirection: "row", // Align items in a row
              gap: 2, // Add spacing between dropdowns
              marginBottom: 2, // Add spacing below the row
            }}
          >
            {/* Dropdown for selecting years */}
            <FormControl fullWidth style={{ width: '30%' }}>
              <InputLabel id="select-year-label">Year</InputLabel>
              <Select
                labelId="select-year-label"
                value={selectedYear}
                onChange={handleYearChange}
                label="Year"
              >
                {yearsOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ width: '30%' }}>
              <InputLabel id="select-ratesType-label">DFP Rates Type</InputLabel>
              <Select
                labelId="select-RatesType-label"
                value={selectedRatesType}
                onChange={handleRatesTypeChange}
                label="RatesType"
              >
                {dfpRatestypeOptions.map((ratesType) => (
                  <MenuItem key={ratesType} value={ratesType}>
                    {ratesType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* Dropdown for selecting categories */}
            {isReseller === true  ? (
                <FormControl fullWidth style={{ width: '30%' }}>
                <InputLabel id="select-resellerType-label">Reseller Types</InputLabel>
                <Select
                    labelId="select-resellerType-label"
                    value={selectedResellerType}
                    onChange={handleResellerTypeChange}
                    label="ResellerType"
                >
                    {resellerTypeOptions.map((resellerType) => (
                    <MenuItem key={resellerType} value={resellerType}>
                        {resellerType}
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            ):(
                <p></p>
              )} 
            <Button style={{ width: '10%' }}variant="contained" color="primary" onClick={handleSubmit} >fetch</Button>
          </Box> 
          <DataGridPro
            columns={columns ? columns : []}
            rows={data ? data : []}
            pageSize={pageSize}
            rowsPerPageOptions={[100, 500, 2500]}
            onPageSizeChange={(size) => setPageSize(size)}
            filterModel={filterModel} // Bind the filter model here
            onFilterModelChange={(newModel) => setFilterModel(newModel)} // Update the filter model on change
            loading={loading}
            pagination
            page={pageNumber}
            onPageChange={(page) => setPageNumber(page)}
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              toolbar: {
                bigQueryTable: true,
                setOpenDFPRatesUpload,
                page,
                targetValue,
                open,
                selectedRows,
                clearFilters: handleClearFilters, // Pass the clear filters function to the toolbar
              },
              filterPanel: {
                linkOperators: [],
                },
            }}
            selectionModel={entries}
            onSelectionModelChange={(model) => {
                setEntries(model);
                         
            }}
          />
          

            
        </Box>
        <DFPRatesUpload
          open={openDFPRatesUpload}   
          close={() => setOpenDFPRatesUpload(false)}       
        ></DFPRatesUpload>
    </>
    
  );
};

export default DfpRatesPage;

// https://localhost:3000/deals?custom_id_stem=SOT
