import "./App.css";
import {CssBaseline, ThemeProvider, Box} from "@mui/material";
import {useState} from "react";
import {LicenseInfo} from "@mui/x-license-pro";

import AppRoutes from "./routes/routes";
import {lightTheme} from "./theme/theme";
import {appStructure} from "./settings";
import {appStructureNew} from "./settings";
import {appStructureSuperUser} from "./settings";
import useScript from "./hook/useScript";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
const role=localStorage.getItem("role")
const usperUser=localStorage.getItem("superUser")
console.log("usperUserusperUser",usperUser)
localStorage.setItem('asset_search', "")
localStorage.setItem('bulk_deals', [])
localStorage.setItem('bulk_exchange_rates', [])
localStorage.setItem("rulesForCompare", JSON.stringify([]));
localStorage.setItem('asset_filter', JSON.stringify(false));
const App = () => {
  const [open, setOpen] = useState(false);
  
  // Import google authentication library
  //useScript('https://accounts.google.com/gsi/client')
if(role=="read only - programmes only")
  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{display: "flex"}}>
        <CssBaseline />
        <AppRoutes
          menuNavigationItems={appStructureNew}
          open={open}
          setOpen={setOpen}
        />
      </Box>
    </ThemeProvider>
  );
else if(usperUser=="true")
    return (
      <ThemeProvider theme={lightTheme}>
        <Box sx={{display: "flex"}}>
          <CssBaseline />
          <AppRoutes
            menuNavigationItems={appStructureSuperUser}
            open={open}
            setOpen={setOpen}
          />
        </Box>
      </ThemeProvider>
    );
else{
    return (
      <ThemeProvider theme={lightTheme}>
        <Box sx={{display: "flex"}}>
          <CssBaseline />
          <AppRoutes
            menuNavigationItems={appStructure}
            open={open}
            setOpen={setOpen}
          />
        </Box>
      </ThemeProvider>
    );
  }
};

export default App;
