import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import * as XLSX from 'xlsx';
import sendRequest from "../services/sendRequest";
import MoonLoader from "react-spinners/MoonLoader";
import { Select, MenuItem, InputLabel } from "@mui/material";

const DfpRatesUpload =({open, close, selected, setRefresh}) => {
const [items, setItems] = useState([]);
const [formData, setFormData] = useState({});
const { enqueueSnackbar } = useSnackbar()
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth, setMaxWidth] = React.useState('sm');
let [loading, setLoading] = useState(false);
let [color, setColor] = useState("#000000");
const [selectedRatesTypeUpload, setSelectedRatesTypeUpload] = useState("");
const [isSelectedRatesType, setIsSelectedRateType] = useState(false);
const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
const [selectedFile, setSelectedFile] = useState(null);
const page ="dfp_rates";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
const handleClose = () => {
    setSelectedRatesTypeUpload("");
    setIsSelectedRateType(false)
    close();
  };

  useEffect(() => {
  });
  const dfpRatestypeOptions = [
    "GPS Rates",
    "LDS Rates",
    "Reseller Rates",
  ];
  const handleRatesTypeChange = (event) => {
    setSelectedRatesTypeUpload(event.target.value);
    setIsSelectedRateType(true)
  };
  const handleFileSelection = (e) => {
    setSelectedFile(e.target.files[0]);
    setIsConfirmationOpen(true); // Open confirmation dialog when a file is selected
  };

  const handleConfirmUpload = () => {
    setIsConfirmationOpen(false);
    if (selectedRatesTypeUpload === "GPS Rates") {
      handleFileUpload(selectedFile);
    } else if (selectedRatesTypeUpload === "LDS Rates") {
      handleFileUploadforLDS(selectedFile);
    } else if (selectedRatesTypeUpload === "Reseller Rates") {
      handleFileUploadforReseller(selectedFile);
    }
  };

  const handleCancelUpload = () => {
    setSelectedFile(null);
    setIsConfirmationOpen(false);
  };
  // handle file upload
  const handleFileUpload = (selectedFile) => {

    let isColumnsMissing = true;
    setLoading(true)
    const file = selectedFile
    console.log("file",file)
    const reader = new FileReader();
    reader.onload = async (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const Papa = require('papaparse');
      const fileData = XLSX.utils.sheet_to_csv(ws, { header: 1 });

      // Parse the CSV data
      const parsedData = Papa.parse(fileData, {
        header: false, // Set to true if the first row contains headers
      });

      // Initialize an array to hold column-wise data
      let columns = [];
      const items = [];

      // Iterate through each row
      parsedData.data.forEach((row, rowIndex) => {
        // Iterate through each cell and organize them into columns
        if (row.length != 6) {
          isColumnsMissing = false;
        }
        row.forEach((cell, colIndex) => {
          // Initialize the column array if it doesn't exist
          if (!columns[colIndex]) {
            columns[colIndex] = [];
          }
          // Add the cell to the appropriate column
          columns[colIndex][rowIndex] = cell;
        });
        // Create an object for each row
        const newObject = {
          ad_type: row[0],
          country_code: row[1],
          country_name: row[2],
          ad_value: row[3],
          currency: row[4],
          year: row[5],
        };
        items.push(newObject);
        
      });
      console.log(items);
      if (isColumnsMissing) {
        try {      
          const response = await sendRequest({
            requestType: "post",
            endpoint: `gps_rates/custom_bulk_create/`,
            requestData: items,
          });
      
          if (response.status === 200) {
            setItems([]);
           
            handleSuccess(response.data.message);
            handleClose();
          } else {
            handleError();
          }
      
        } catch (error) {
          console.error("An error occurred:", error);
          handleError();
        } finally {
          setItems([]);
          setLoading(false);
          handleClose();
        }
      } else {
        
          setItems([]);
          handleErrorForMoreThanTwoCoulumnsMissting();
          setLoading(false)
          handleClose();
        
        
      }
    };
    reader.readAsBinaryString(file);
  }
  const handleFileUploadforLDS = (selectedFile) => {

    let isColumnsMissing = true;
    setLoading(true)
    const file = selectedFile
    console.log("file",file)
    const reader = new FileReader();
    reader.onload = async (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const Papa = require('papaparse');
      const fileData = XLSX.utils.sheet_to_csv(ws, { header: 1 });

      // Parse the CSV data
      const parsedData = Papa.parse(fileData, {
        header: false, // Set to true if the first row contains headers
      });

      // Initialize an array to hold column-wise data
      let columns = [];
      const items = [];

      // Iterate through each row
      parsedData.data.forEach((row, rowIndex) => {
        // Iterate through each cell and organize them into columns
        if (row.length != 6) {
          isColumnsMissing = false;
        }
        row.forEach((cell, colIndex) => {
          // Initialize the column array if it doesn't exist
          if (!columns[colIndex]) {
            columns[colIndex] = [];
          }
          // Add the cell to the appropriate column
          columns[colIndex][rowIndex] = cell;
        });
        // Create an object for each row
        const newObject = {
          ad_type: row[0],
          country_code: row[1],
          country_name: row[2],
          ad_value: row[3],
          currency: row[4],
          year: row[5],
        };
        items.push(newObject);
        
      });
      console.log(items);
      if (isColumnsMissing) {
        try {      
          const response = await sendRequest({
            requestType: "post",
            endpoint: `lds_rates/custom_bulk_create/`,
            requestData: items,
          });
      
          if (response.status === 200) {
            setItems([]);
           
            handleSuccess(response.data.message);
            handleClose();
          } else {
            handleError();
          }
      
        } catch (error) {
          console.error("An error occurred:", error);
          handleError();
        } finally {
          setItems([]);
          setLoading(false);
          handleClose();
        }
      } else {
        
          setItems([]);
          handleErrorForMoreThanTwoCoulumnsMissting();
          setLoading(false)
          handleClose();
        
        
      }
    };
    reader.readAsBinaryString(file);
  }
  const handleFileUploadforReseller = (selectedFile) => {

    let isColumnsMissing = true;
    setLoading(true)
    const file = selectedFile
    console.log("file",file)
    const reader = new FileReader();
    reader.onload = async (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const Papa = require('papaparse');
      const fileData = XLSX.utils.sheet_to_csv(ws, { header: 1 });

      // Parse the CSV data
      const parsedData = Papa.parse(fileData, {
        header: false, // Set to true if the first row contains headers
      });

      // Initialize an array to hold column-wise data
      let columns = [];
      const items = [];

      // Iterate through each row
      parsedData.data.forEach((row, rowIndex) => {
        // Iterate through each cell and organize them into columns
        if (row.length != 5) {
          isColumnsMissing = false;
        }
        row.forEach((cell, colIndex) => {
          // Initialize the column array if it doesn't exist
          if (!columns[colIndex]) {
            columns[colIndex] = [];
          }
          // Add the cell to the appropriate column
          columns[colIndex][rowIndex] = cell;
        });
        // Create an object for each row
        const newObject = {
          resellers_name: row[0],
          ad_type: row[1],
          rate: row[2],
          currency_code: row[3],
          year: row[4],
        };
        items.push(newObject);
        
      });
      console.log(items);
      if (isColumnsMissing) {
        try {      
          const response = await sendRequest({
            requestType: "post",
            endpoint: `reseller_rates/custom_bulk_create/`,
            requestData: items,
          });
      
          if (response.status === 200) {
            setItems([]);
           
            handleSuccess(response.data.message);
            handleClose();
          } else {
            handleError();
          }
      
        } catch (error) {
          console.error("An error occurred:", error);
          handleError();
        } finally {
          setItems([]);
          setLoading(false);
          handleClose();
        }
      } else {
        
          setItems([]);
          handleErrorForMoreThanTwoCoulumnsMissting();
          setLoading(false)
          handleClose();
        
        
      }
    };
    reader.readAsBinaryString(file);
  }

  const handleError = () => {
    enqueueSnackbar('There is an Error with the file, Please Check the .csv file and Reupload it!', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleErrorForMoreThanTwoCoulumnsInFile = () => {
    enqueueSnackbar('Ensure that the file contains only these 3 Columns [Currency, Date of entry, DFP Rate] in the specified order and does not include any headers', {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleErrorForMoreThanTwoCoulumnsMissting = () => {
    enqueueSnackbar('Some of the DFP rate records have empty details. Please fill them in and re-upload the file', {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 3000,
    });
  };
  return (
    <Dialog
    open={open}
    onClose={() => {
      close();
    }}
    >
      <DialogContent>
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
          >
            <MoonLoader
              color={color}
              loading={loading}
              cssOverride={override}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <DialogTitle sx={{ textAlign: 'center' }}>Upload CSV</DialogTitle>
            <FormControl disabled={isSelectedRatesType} fullWidth style={{ width: '300px', margin:'0px 0px 0px 143px',}}>
              <InputLabel id="select-ratesType-label">DFP Rates Type</InputLabel>
              <Select
                labelId="select-RatesType-label"
                value={selectedRatesTypeUpload}
                onChange={handleRatesTypeChange}
                label="RatesType"
              >
                {dfpRatestypeOptions.map((ratesType) => (
                  <MenuItem key={ratesType} value={ratesType}>
                    {ratesType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedRatesTypeUpload === "GPS Rates"  ? (
            <DialogContent sx={{ marginBottom: 2 }}>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              Upload a CSV file to Create the new GPS Rates!
              </DialogContentText>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              <b>The .CSV file with no headers should contain the following columns in the specified order: 1) Ad Type, 2) Country Code, 3) Country Name, 4) Rate, 5) Currency, 6) Year.</b>
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >        
              <FormControl sx={{ mt: 2, minWidth: 120 }}>              
                <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileSelection}/>
              </FormControl>
              </Box>
            </DialogContent>
            ):(
              null
            )} 
            {selectedRatesTypeUpload === "LDS Rates"  ? (
            <DialogContent sx={{ marginBottom: 2 }}>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              Upload a CSV file to Create the new LDS Rates!
              </DialogContentText>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              <b>The .CSV file with no headers should contain the following columns in the specified order: 1) Ad Type, 2) Country Code, 3) Country Name, 4) Rate, 5) Currency, 6) Year.</b>
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >        
              <FormControl sx={{ mt: 2, minWidth: 120 }}>              
                <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileSelection}/>
              </FormControl>
              </Box>
            </DialogContent>
            ):(
              null
            )} 
            {selectedRatesTypeUpload === "Reseller Rates"  ? (
            <DialogContent sx={{ marginBottom: 2 }}>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              Upload a CSV file to Create the new Reseller Rates!
              </DialogContentText>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              <b>The .CSV file with no headers should contain the following columns in the specified order: 1) Reseller Name, 2) Ad Type, 3) Rate, 4) Currency code, 5) Year.</b>
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >        
              <FormControl sx={{ mt: 2, minWidth: 120 }}>              
                <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileSelection}/>
              </FormControl>
              </Box>
            </DialogContent>
            ):(
              null
            )} 
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
      </DialogContent>
      <Dialog
        open={isConfirmationOpen}
        onClose={handleCancelUpload}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">
          Confirm File Upload
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to upload data for {selectedRatesTypeUpload} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpload} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpload} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
    
  );
};

export default DfpRatesUpload;
