import {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  InputAdornment,
} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {useSnackbar} from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";

import sendRequest from "../services/sendRequest";
import SelectComponent from "./singleSelect";
import EditRelatedField from "./relatedFieldSelect";
import ListSelect from "./listSelect";
import DealRule from "./dealRule";
import ProgrammeRule from "./programmeRule";
import {validate} from "../utils/validators";
import Series from "./series";
import StartDate from "./startDate";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import './YourStyles.css'; // Ensure to import the CSS file
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { format } from 'date-fns';

const role=localStorage.getItem("role")

const AreYouSureDialog = ({open, confirm, cancel, message}) => (
  <Dialog open={open}>
    <DialogTitle>{message}</DialogTitle>
    <DialogContent
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button onClick={cancel}>No</Button>
      <Button onClick={confirm}>Yes</Button>
    </DialogContent>
  </Dialog>
);

const EditData = ({
  open,
  openEditDialogue,
  options,
  order,
  rowToEdit,
  page,
  setRefresh,
  isAdd,
}) => {
  const {enqueueSnackbar} = useSnackbar();

  const [data, setData] = useState({});
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  console.log(rowToEdit);
  const storedData = localStorage.getItem('audit');
  let auditData = [];
  const dealRules = localStorage.getItem("rulesForCompare");
  let parsedData;

  if(parsedData != undefined) {
    // Safely parse the JSON string
    try {
        parsedData = JSON.parse(dealRules);
    } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
        parsedData = [];
    }

    // Check if parsedData is an empty array or null
    if (parsedData == null || parsedData.length === 0) {
        if (rowToEdit && Object.keys(rowToEdit).length > 0) {
            localStorage.setItem("rulesForCompare", JSON.stringify(rowToEdit.rules));
        }
    }
  }

  if(storedData != undefined) {
    try {
      // Parse the JSON string back into an object or array      
      auditData = JSON.parse(storedData);
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
    }
  } 

 
  const reportShareData = {
    custom_id: rowToEdit.custom_id,
    deal_id: rowToEdit.deal,
    quarter: data.quarter ? data.quarter : rowToEdit.quarter,
    report_file: data.report_file ? data.report_file : rowToEdit.report_file,
    year: data.year ? data.year : rowToEdit.year,
    failure: data.failure ? data.failure : rowToEdit.failure,
    sent: data.sent ? data.sent : rowToEdit.sent,
    can_send: data.can_send ? data.can_send : rowToEdit.can_send,
  };

  options.series = {
    "id": {
      "type": "str",
      "required": true,
      "read_only": false,
      "label": "Id"
    },
    "id1": {
      "type": "str",
      "required": true,
      "read_only": false,
      "label": "id1"
    },
    "name": {
      "type": "str",
      "required": true,
      "read_only": false,
      "label": "Name"
    }
  }
  if(page==="programmes"){
    order = [
      "title",        
      "deal",        
      "custom_id",    
      "programme_rules"
    ];
    }
  console.log(reportShareData);

  const submitEdit = async () => {
    // check if any changes have actually been made
    if (Object.keys(data).length === 0) {
      enqueueSnackbar("No changes to update", {variant: "info"});
      return;
    }
    console.log(reportShareData);
    if(data != null && page === "deals" ){ 
      if(data.rules===undefined){
        data.is_rules = false
      }else{
        if(data.rules.length > 0){
          data.is_rules=true
        }else{
          data.is_rules = false
        }
      }
    }
    if(data != null && page === "programmes"){ 
      if(data.programme_rules===undefined){
        data.is_rules = false
      }else{
        if(data.programme_rules.length > 0){
          data.is_rules=true
        }else{
          data.is_rules = false
        }
      }
    }

    // Validate uniqueness of source, country_code, and start_date if there are deal rules
    if(data != null && page === "deals"){
      if(data.client == null){
        delete data.client
      }
      const fieldName = 'client_rev_share';
      const isFieldPresent = fieldName in data;
        if(isFieldPresent){
          if(data.client_rev_share === ""){
            enqueueSnackbar(
              "A deal can’t be submitted without a Client Share",
              {variant: "warning"}
            );
            return;
          }
        } 
        if(data.client_rev_share < 0 || data.client_rev_share > 100){
          enqueueSnackbar(
            "Client Share must be between 0 and 100",
            {variant: "warning"}
          );
          return;
        }
        
        if (("permitted_deduction" in data)){
          if(data.permitted_deduction != null && data.permitted_deduction != undefined){
            if(!Number.isInteger(parseInt(data.permitted_deduction)) 
            || parseInt(data.permitted_deduction) < 0 // Ensure it's >= 0
            || parseInt(data.permitted_deduction) > 100){
              enqueueSnackbar(
                "Permitted Deduction must be a positive integer between 0 and 100",
                { variant: "warning" }
              );
              return;
            }
          }
        }     


        // As pe the Amy's suggestion added code to remove the report_emails from deal level.
        if(data != null && data.report_emails == ""){
          data.report_emails = null;
        }
        if (data.rules && data.rules.length > 0) {
          for(let i = 0; i < data.rules.length; i++) {
            if('end_date' in data.rules[i]) {
              if(data.rules[i].end_date === null){
                data.rules[i].end_date = "2999-01-01";
              } else {
                data.rules[i].end_date = data.rules[i].end_date;
              }          
            } else {
              data.rules[i].end_date = "2999-01-01";
            }
            if(data.rules[i].start_date >= data.rules[i].end_date) {
              enqueueSnackbar(
                "All the deal rules end date must be greater than start date!",
                {variant: "warning"}
              );
              return;
            }
            if (data.rules[i].client_rev_share == null) {
              enqueueSnackbar(
                "All the Deal Rules Needs to have client Share.",
                {variant: "warning"}
              );
              return;
            }
            if(data.rules[i].client_rev_share < -1 || data.rules[i].client_rev_share > 100){
              enqueueSnackbar(
                "Deal Rule client share must be between -1 to 100",
                {variant: "warning"}
              );
              return;
            }
          }
        }
        let isCanSend = false;
        if ('can_send' in data) {
          isCanSend = data.can_send;
        } else {
          isCanSend = false;
        }
        let isReportEmails = "";
        if ('report_emails' in data) {
          isReportEmails = data.report_emails;
        } else {
          isReportEmails = rowToEdit.report_emails;
        }
    
        if(isCanSend) {
          if(isReportEmails == "" || isReportEmails == null) {
            enqueueSnackbar(
              "Report Email Address can’t be empty when the “Can Send?” flag is selected!",
              {variant: "warning"}
            );
            return;
          }
        }
    } 
    if (data.rules && data.rules.length > 0) {
      for (let i = 0; i < data.rules.length; i++) {
        if (data.rules[i].country_code == "ROW") {
          delete data.rules[i].country_code;
        }
      }
    }
    
    if (data.rules && data.rules.length > 0) {
      for (let i = 0; i < data.rules.length; i++) {
        if (data.rules[i].client_rev_share == null) {
          enqueueSnackbar(
            "All the Deal Rules Needs to have client Share.",
            {variant: "warning"}
          );
          return;
        }
      }
      const uniqueEntries = new Set();
      let isInvalid = false;
      data.rules.forEach((entry) => {
        const key = `${entry.source}-${entry.country_code}-${entry.start_date}`;
        if (uniqueEntries.has(key)) {
          isInvalid = true;
          return;
        }
        uniqueEntries.add(key);
      });

      if (isInvalid) {
        enqueueSnackbar(
          "Each entry must have unique source, country code, and start date combination.",
          {variant: "warning"}
        );
        return;
      }
    } else if (data.programme_rules && data.programme_rules.length > 0) {
      for (let i = 0; i < data.programme_rules.length; i++) {
        if (data.programme_rules[i].client_rev_share == null) {
          enqueueSnackbar(
            "All the Program Rules Needs to have client Share.",
            {variant: "warning"}
          );
          return;
        }
        if(data.programme_rules[i].client_rev_share < -1 || data.programme_rules[i].client_rev_share > 100){
          enqueueSnackbar(
            "Program Rule client share must be between -1 to 100",
            {variant: "warning"}
          );
          return;
        }
      }
      if (data.programme_rules && data.programme_rules.length > 0) {
        for(let i = 0; i < data.programme_rules.length; i++) {
          if('end_date' in data.programme_rules[i]) {
            if(data.programme_rules[i].end_date === null){
              data.programme_rules[i].end_date = "2999-01-01";
            } else {
              data.programme_rules[i].end_date = data.programme_rules[i].end_date;
            }          
          } else {
            data.programme_rules[i].end_date = "2999-01-01";
          }
          if(data.programme_rules[i].start_date >= data.programme_rules[i].end_date) {
            enqueueSnackbar(
              "All the programme rules end date must be greater than start date!",
              {variant: "warning"}
            );
            return;
          }
        }
      }

      const uniqueEntries = new Set();
      let isInvalid = false;

      data.programme_rules.forEach((entry) => {
        const key = `${entry.source}-${entry.country_code}-${entry.start_date}`;
        if (uniqueEntries.has(key)) {
          isInvalid = true;
          return;
        }
        uniqueEntries.add(key);
      });

      if (isInvalid) {
        enqueueSnackbar(
          "Each entry must have unique source, country code, and start date combination.",
          {variant: "warning"}
        );
        return;
      }
    }
    if(data && data.series){
      const fieldValues = data.series.map(item => item["id"]);
      const uniqueValues = new Set(fieldValues);
      if(uniqueValues.size !== fieldValues.length){
        enqueueSnackbar(
          "Each series entry must have a unique ID",
          {variant: "warning"}
        );
        return;
      }

      let isSeriesEmpty = false;
      data.series.forEach((entry) => {      
        if (Object.keys(entry).length === 0 && entry.constructor === Object) {
          isSeriesEmpty = true;
        }
      });
      if(isSeriesEmpty) {
        enqueueSnackbar(
          "Their is an empty series included in this program.",
          {variant: "warning"}
        );
        return;
      }

      let isSeriesNamePresent = true;
      data.series.forEach((item) => {      
        if (item.hasOwnProperty("name")) {
          isSeriesNamePresent = true;
        } else {
          isSeriesNamePresent = false;
        }
      });
      if(!isSeriesNamePresent) {
        enqueueSnackbar(
          "The Series Name entry cannot be empty",
          {variant: "warning"}
        );
        return;
      }

      let isSeriesIdPresent = true;
      data.series.forEach((item) => {     
        if (item.hasOwnProperty("id")) {
          isSeriesIdPresent = true;
        } else {
          isSeriesIdPresent = false;
        }
      });
      if(!isSeriesIdPresent) {
        enqueueSnackbar(
          "The Series ID entry cannot be empty",
          {variant: "warning"}
        );
        return;
      }
    }    
    
    // const invalidFields = validate(data, page);
    // if (invalidFields.length > 0) {
    //   invalidFields.forEach((field) =>
    //     enqueueSnackbar(field, {variant: "error"})
    //   );
    //   return;
    // }
    if (isAdd) {
      if(data.dates!=null){
        data['start_date']=data.dates[0].start_date
        if(data.dates[0].end_date!=null){
          data['end_date']=data.dates[0].end_date
        }else{
          data['end_date']="2999-01-01"
        }
        
        
      }
      const response = await sendRequest({
        requestType: "post",
        endpoint: `${page}/`,
        requestData: data,
      });
      if (response?.status === 201) {
        enqueueSnackbar("Data successfully added!", {variant: "success"});
        openEditDialogue(false);
        setRefresh((oldState) => !oldState);
        if(page === "deals") {
          let auditPage= "audit"
          const auditPageJson = {
            table: page, 
            user: localStorage.getItem('username'), 
            action : "Create",
            changes: [],
            entity_id : data.custom_id_stem
          };
          const auditPageResponse = await sendRequest({
            requestType: "post",
            endpoint: `${auditPage}/create_audit_records/`,
            requestData: auditPageJson,
          });
          console.log("Audit Page Response ", auditPageResponse)
        }
        if(page === "programmes") {
          let auditPage= "auditprogramme"
          const auditPageJson = {
            table: page, 
            user: localStorage.getItem('username'), 
            action : "Create",
            changes: [],
            entity_id : data.custom_id
          };
          const auditPageResponse = await sendRequest({
            requestType: "post",
            endpoint: `${auditPage}/create_audit_programme_records/`,
            requestData: auditPageJson,
          });
          console.log("Audit Page Response ", auditPageResponse)
        }
      } else if (response?.response?.request?.responseText) {
        // iterate over error messages and snack each error.
        const responseErrors = JSON.parse(
          response?.response?.request?.responseText
        );
        Object.keys(responseErrors).forEach((fieldName) =>
          responseErrors[fieldName].forEach((errorMessage) => {
            if (typeof errorMessage == "object") {
              console.log(errorMessage);
              Object.keys(errorMessage).forEach((key) => {
                enqueueSnackbar(
                  `${fieldName} - ${key} - ${errorMessage[key][0]}`,
                  {variant: "warning"}
                );
              });
            } else {
              enqueueSnackbar(`${fieldName} - ${errorMessage}`, {
                variant: "warning",
              });
            }
          })
        );
      } else {
        enqueueSnackbar("There was an error adding the data.", {
          variant: "warning",
        });
      }
      // for editing an existing row
    } else {
      let patchId;
      if (!rowToEdit?.id) {
        if (!rowToEdit?.custom_id) {
          patchId = rowToEdit.custom_id_stem;
        } else {
          patchId = rowToEdit.custom_id;
        }
      } else {
        patchId = rowToEdit.id;
      }
      if(page === "reports" && data){
        if ('sent' in data){
          reportShareData.sent = data.sent;
          console.log("sent");
        }
        if ('cansend' in data){
          reportShareData.cansend = data.cansend;
          console.log("cansend");
        }     
      }
      console.log(patchId);
      if(data.dates!=null){
        data['start_date']=data.dates[0].start_date
        if(data.dates[0].end_date!=null){
          data['end_date']=data.dates[0].end_date
        }else{
          data['end_date']="2999-01-01"  
        }
      }
      const response = await sendRequest({
        requestType: "patch",
        endpoint: page === "reports" ? `${page}/` : `${page}/${patchId}/`,
        requestData: page === "reports" ? reportShareData : data,
      });
      console.log(response);
      if (response?.status === 200) {
        enqueueSnackbar("Data successfully updated!", {variant: "success"});
        setRefresh((oldState) => !oldState);
        openEditDialogue(false);
        setData({});
        if(page === "deals" || page === "programmes") {
          let changes = [];
          console.log(localStorage.getItem("rulesForCompare"));
          let sensitiveAlphanumericInfo1 = JSON.parse(localStorage.getItem("rulesForCompare"));

          if(page === "deals") {
            // Assuming each rule has a unique 'id' property
            if (data && data.rules.length > 0) {
              data.rules.forEach((newRule) => {
                const oldRule = sensitiveAlphanumericInfo1.find(rule => rule.id === newRule.id);

                if (oldRule) {
                  // Compare existing rules for changes
                  for (const key in newRule) {
                    if (newRule[key] !== oldRule[key]) {
                      changes.push({
                        field_name: `rules[${newRule.id}].${key}`,
                        new_value: newRule[key],
                        old_value: oldRule[key]
                      });
                    }
                  }
                } else {
                  // Track newly added records
                  changes.push({
                    field_name: `rules[${newRule.id}]`,
                    new_value: newRule,
                    old_value: null
                  });
                }
              });

              // Check for deleted rules
              sensitiveAlphanumericInfo1.forEach((oldRule) => {
                const newRule = data.rules.find(rule => rule.id === oldRule.id);
                if (!newRule) {
                  changes.push({
                    field_name: `rules[${oldRule.id}]`,
                    new_value: null,
                    old_value: oldRule
                  });
                }
              });
            } else {
              if ( sensitiveAlphanumericInfo1 && sensitiveAlphanumericInfo1.length > 0) {
                sensitiveAlphanumericInfo1.forEach((oldRule) => {
                  changes.push({
                    field_name: `rules[${oldRule.id}]`,
                    new_value: null,
                    old_value: oldRule
                  });
                });
              }
            }
            
            for (const key in data) {
              if(key != "rules") {
                if (data.hasOwnProperty(key) && rowToEdit[key] !== data[key]) {
                  changes.push({
                    field_name: key,
                    new_value: data[key],
                    old_value: rowToEdit[key]
                  });
                }
              }         
            }
          }

          if(page === "programmes") {            
            // Assuming each rule has a unique 'id' property
            if (data && data.programme_rules.length > 0) {
              data.programme_rules.forEach((newRule) => {
                const oldRule = sensitiveAlphanumericInfo1.find(rule => rule.id === newRule.id);

                if (oldRule) {
                  // Compare existing programme_rules for changes
                  for (const key in newRule) {
                    if (newRule[key] !== oldRule[key]) {
                      changes.push({
                        field_name: `programme_rules[${newRule.id}].${key}`,
                        new_value: newRule[key],
                        old_value: oldRule[key]
                      });
                    }
                  }
                } else {
                  // Track newly added records
                  changes.push({
                    field_name: `programme_rules[${newRule.id}]`,
                    new_value: newRule,
                    old_value: null
                  });
                }
              });

              // Check for deleted programme_rules
              sensitiveAlphanumericInfo1.forEach((oldRule) => {
                const newRule = data.programme_rules.find(rule => rule.id === oldRule.id);
                if (!newRule) {
                  changes.push({
                    field_name: `programme_rules[${oldRule.id}]`,
                    new_value: null,
                    old_value: oldRule
                  });
                }
              });
            } else {
              if ( sensitiveAlphanumericInfo1 && sensitiveAlphanumericInfo1.length > 0) {
                sensitiveAlphanumericInfo1.forEach((oldRule) => {
                  changes.push({
                    field_name: `programme_rules[${oldRule.id}]`,
                    new_value: null,
                    old_value: oldRule
                  });
                });
              }
            }

            if (data && data.series != null && data.series.length > 0) {
              let existingSeriesDetails = JSON.parse(localStorage.getItem('seriesForCompare'));
              if(existingSeriesDetails === null){
                existingSeriesDetails = []
              }
              data.series.forEach((newSeries) => {
                const oldSeries = existingSeriesDetails.find(rule => rule.id === newSeries.id)            

                if (oldSeries) {
                  // Compare existing series for changes
                  for (const key in newSeries) {
                    if (newSeries[key] !== oldSeries[key]) {
                      changes.push({
                        field_name: `series[${newSeries.id}].${key}`,
                        new_value: newSeries[key],
                        old_value: oldSeries[key]
                      });
                    }
                  }
                } else {
                  // Track newly added records
                  changes.push({
                    field_name: `series[${newSeries.id}]`,
                    new_value: newSeries,
                    old_value: null
                  });
                }
              });

              // Check for deleted series
              existingSeriesDetails.forEach((oldSeries) => {
                const newSeries = data.series.find(rule => rule.id === oldSeries.id);
                if (!newSeries) {
                  changes.push({
                    field_name: `series[${oldSeries.id}]`,
                    new_value: null,
                    old_value: oldSeries
                  });
                }
              });
            } else {
              const existingSeriesDetails = JSON.parse(localStorage.getItem('seriesForCompare'));
              if ( existingSeriesDetails && existingSeriesDetails.length > 0) {
                existingSeriesDetails.forEach((oldSeries) => {
                  changes.push({
                    field_name: `series[${oldSeries.id}]`,
                    new_value: null,
                    old_value: oldSeries
                  });
                });
              }
            }
            console.log(changes);            
            for (const key in data) {
              if(key != "programme_rules" && key != "series" && key != "is_rules") {
                if (data.hasOwnProperty(key) && rowToEdit[key] !== data[key]) {
                  changes.push({
                    field_name: key,
                    new_value: data[key],
                    old_value: rowToEdit[key]
                  });
                }
              }         
            }
          }
        
        console.log(JSON.stringify(changes, null, 2));          
        if(changes && changes.length > 0 && page === "deals") {
          let auditPage= "audit"
          const auditPageJson = {
            table: page, 
            user: localStorage.getItem('username'), 
            action : "Update",
            changes: changes,
            entity_id : localStorage.getItem('entity_id') 
          };
          const auditPageResponse = await sendRequest({
            requestType: "post",
            endpoint: `${auditPage}/create_audit_records/`,
            requestData: auditPageJson,
          });
          console.log("Audit Page Response ", auditPageResponse)
        }
        if(changes && changes.length > 0 && page === "programmes") {
          let auditPage= "auditprogramme"
          const auditPageJson = {
            table: page, 
            user: localStorage.getItem('username'), 
            action : "Update",
            changes: changes,
            entity_id : localStorage.getItem('entity_id') 
          };
          const auditPageResponse = await sendRequest({
            requestType: "post",
            endpoint: `${auditPage}/create_audit_programme_records/`,
            requestData: auditPageJson,
          });
          console.log("Audit Page Response ", auditPageResponse)
        }
        localStorage.setItem("rulesForCompare", JSON.stringify([]));
      }
      return;
    }   
      if (response.response.status === 400) {
        enqueueSnackbar(response.response.data[0], {variant: "warning"});
        localStorage.setItem("rulesForCompare", JSON.stringify([]));
        return;
      }

      if (response?.response?.status >= 500) {
        localStorage.setItem("rulesForCompare", JSON.stringify([]));
        enqueueSnackbar(
          "There was an internal server error, data not updated",
          {variant: "warning"}
        );
        return;
      }

      if (response?.response?.request?.responseText) {
        // iterate over error messages and snack each error.
        const responseErrors = JSON.parse(
          response?.response?.request?.responseText
        );
        Object.keys(responseErrors).forEach((fieldName) =>
          responseErrors[fieldName].forEach((errorMessage) =>
            enqueueSnackbar(`${fieldName} - ${errorMessage}`, {
              variant: "warning",
            })
          )
        );
        return;
      }

      enqueueSnackbar("There was an error updating the data.", {
        variant: "warning",
      });
    }
    localStorage.setItem("rulesForCompare", JSON.stringify([]));
  };

  const handleDelete = async ({confirmed}) => {
    if (!confirmed) setAreYouSureOpen(true);
    else {
      let deleteId;
      if (!rowToEdit?.id) {
        if (!rowToEdit?.custom_id) {
          deleteId = rowToEdit.custom_id_stem;
        } else {
          deleteId = rowToEdit.custom_id;
        }
      } else {
        deleteId = rowToEdit.id;
      }
      const response = await sendRequest({
        requestType: "delete",
        endpoint: `${page}/${deleteId}/`,
        //endpoint: page === "reports" ? `${page}/` : `${page}/${deleteId}/`,
      });
      if (
        response?.status === 202 ||
        response?.status === 200 ||
        response?.status === 204
      ) {
        enqueueSnackbar("Data successfully deleted!", {variant: "success"});
        openEditDialogue(false);
        const pageDetails = "audit"
        if(page === "deals") {
          const details = await sendRequest({
            requestType: "delete",
            endpoint: `${pageDetails}/delete_audit/`,
            requestData: {
              id: deleteId,
            },
          });
        }
      
        if(page === "programmes") {
          const details = await sendRequest({
            requestType: "delete",
            endpoint: `${'auditprogramme'}/delete_programme_audit/`,
            requestData: {
              id: deleteId,
            },
          });
        }
        setRefresh((oldState) => !oldState);
      } else {
        enqueueSnackbar("There was an error deleting the data.", {
          variant: "warning",
        });
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") return;
          setData({});
          openEditDialogue(false);
        }}
        fullWidth={true}
        sx={{
          '& .MuiDialog-paper': {
            width: '80%', // Adjust the width as needed
            height: '80%', // Adjust the height as needed
            maxWidth: 'none', // Override the default maxWidth
            maxHeight: 'none', // Override the default maxHeight
          },
        }}
      >
        <Stack
          m={1}
          mb={-2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DialogTitle>{`${isAdd ? "Add" : "Edit"} Data`}</DialogTitle>
          {!isAdd && role=== 'full access' && (
            <Button
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
              color="error"
              sx={{height: 40}}
            >
              Delete
            </Button>
          )}
        </Stack>
        <DialogContent>
          {options &&
            order.map((header, index) => {
              if (header === "rules") {
                return page === "deals" ? (
                  <DealRule
                    key={"rules"}
                    options={options.rules.child.children}
                    data={data}
                    setData={setData}
                    rowToEdit={rowToEdit}
                  />
                ) : null;
              } else if (header === "programme_rules") {
                return page === "programmes" ? (
                  <>
                    <ProgrammeRule
                      key={"programme_rules"}
                      data={data}
                      setData={setData}
                      options={options}
                      rowToEdit={rowToEdit}
                      isNew={isAdd}
                    />
                    <Series
                      key={"series"}
                      data={data}
                      setData={setData}
                      options={options}
                      rowToEdit={rowToEdit}
                      isNew={isAdd}
                    />
                  </>
                ) : null;   
                            
              }else if (page === "channels" && header=== "start_date") {
                return page === "channels" ? (
                  <>
                    <StartDate
                      key={"dates"}
                      data={data}
                      setData={setData}
                      options={options}
                      rowToEdit={rowToEdit}
                      isNew={isAdd}
                    />
                  </>
                ) : null;                
              }else if (page === "channels" && header=== "end_date") {
                return page === "channels" ? (
                  <>
                    
                  </>
                ) : null;                
              }else if (options[header].type === "boolean" && header=== "is_rules") {
                return page === "deals" ? (
                  <>
                    
                  </>
                ) : null;                
              } else if (options[header].type === "boolean") {
                return (
                  <FormControlLabel
                    key={header}
                    control={
                      <Checkbox
                        defaultChecked={rowToEdit[header]}
                        onChange={(e) =>
                          setData((oldState) => {
                            return {...oldState, [header]: e.target.checked};
                          })
                        }
                      />
                    }
                    label={`${header}?`}
                  />
                );
              } else if (options[header].type === "datetime") {
                return (
                  <Box key={header} sx={{marginY: 2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label={options[header]?.label || header}
                        value={rowToEdit[header]}
                        disabled={options[header].read_only}
                        onChange={(newValue) => {
                          console.log(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                );
              } else if (options[header].type === "decimal") {
                const min = options[header].min_value;
                const max = options[header].max_value;
                const adorn = max === 100 && min === 0 ? "%" : "";
                return (
                  <TextField
                    key={header}
                    margin="normal"
                    type="number"
                    disabled={options[header].read_only}
                    label={options[header]?.label || header}
                    defaultValue={rowToEdit[header]}
                    fullWidth
                    onChange={(e) =>
                      setData((oldState) => {
                        return {...oldState, [header]: e.target.value};
                      })
                    }
                    inputProps={{
                      step: 1,
                      min: min,
                      max: max,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {adorn}
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              } else if (options[header].type === "integer") {
                return (
                  <TextField
                    key={header}
                    margin="normal"
                    type={"number"}
                    disabled={options[header].read_only}
                    label={options[header]?.label || header}
                    defaultValue={rowToEdit[header] ?? 0}
                    fullWidth
                    onChange={(e) =>
                      setData((oldState) => {
                        return {...oldState, [header]: e.target.value};
                      })
                    }
                  />
                );
              } else if (options[header].type === "float") {
                return (
                  <TextField
                    key={header}
                    margin="normal"
                    type={"number"}
                    label={options[header]?.label || header}
                    disabled={options[header].read_only}
                    defaultValue={rowToEdit[header] ?? 0.0}
                    fullWidth
                    onChange={(e) =>
                      setData((oldState) => {
                        return {...oldState, [header]: e.target.value};
                      })
                    }
                  />
                );
              } else if (options[header].type === "field") {
                return (
                  <SelectComponent
                    key={header}
                    options={options}
                    header={header}
                    label={options[header].label || header}
                    setData={setData}
                    rowToEdit={rowToEdit}
                  />
                );
              } else if (options[header].type === "choice") {
                return (
                  <SelectComponent
                    key={header}
                    options={options}
                    header={header}
                    label={options[header].label || header}
                    setData={setData}
                    rowToEdit={rowToEdit}
                  />
                );
              } else if (options[header].type === "relatedField") {
                return (
                  <EditRelatedField
                    key={header}
                    fieldName={header}
                    label={options[header].label}
                    option={options[header].child.children}
                    setData={setData}
                    data={data}
                    rowToEdit={rowToEdit}
                    page={page}
                  />
                );
              } else if (options[header].type === "list") {
                return (
                  <ListSelect
                    key={header}
                    fieldName={header}
                    label={options[header].label}
                    setData={setData}
                    data={data}
                    rowToEdit={rowToEdit}
                  />
                );
              } else {
                return (
                  <TextField
                    key={header}
                    margin="normal"
                    disabled={
                      options[header].label === "Deal Name" &&
                      page === "reports"
                        ? true
                        : options[header].read_only
                    }
                    label={options[header]?.label || header}
                    defaultValue={rowToEdit[header] ?? ""}
                    fullWidth
                    onChange={(e) =>
                      setData((oldState) => {
                        return {...oldState, [header]: e.target.value};
                      })
                    }
                  />
                );
              }
            })}
            <DialogActions>
              <Button
                onClick={() => {
                  openEditDialogue(false);
                  setData({});
                }}
              >
                Cancel
              </Button>
              <Button onClick={() => submitEdit(data)}>Submit</Button>
            </DialogActions>
            {!isAdd && auditData?.length > 0 && (
              <TableContainer component={Paper} sx={{ marginTop: 2 }} className="c-list__table-structure">
                <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#9e9e9e', marginBottom: '10px', padding: '10px 0' }}>
                  <FactCheckIcon style={{ marginRight: '8px' }} />
                  <h4 style={{ margin: 0 }}>AUDIT</h4>
                </div>
                <Table className="mat-table">
                  <TableHead>
                    <TableRow className="mat-header-row">
                      <TableCell className="mat-header-cell">Table</TableCell>
                      <TableCell className="mat-header-cell">User</TableCell>
                      <TableCell className="mat-header-cell">Created at</TableCell>
                      <TableCell className="mat-header-cell">Action</TableCell>
                      <TableCell className="mat-header-cell">Changes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {auditData.map((row, index) => (
                      <TableRow key={index} className="mat-row">
                        <TableCell className="mat-cell">
                          {row.table === "deals" && "Deal"} 
                          {row.table === "programmes" && "Programme"}
                          {row.table === "clients" && "Client"}
                        </TableCell>
                        <TableCell className="mat-cell">{row.user}</TableCell>
                        <TableCell className="mat-cell">
                          {format(new Date(row.created_at), 'yyyy-MM-dd HH:mm:ss')}
                        </TableCell>
                        <TableCell className="mat-cell">{row.action}</TableCell>
                        <TableCell className="mat-cell">
                        {(Array.isArray(row.changes) ? row.changes : []).map((change, idx) => (
                          <div key={idx}>
                            <strong>{change.field_name}:</strong>
                            <br />
                            New Value: 
                            {typeof change.new_value === 'object' ? (
                              <pre>{JSON.stringify(change.new_value, null, 2)}</pre>
                            ) : (
                              String(change.new_value)
                            )}
                            <br />
                            Old Value: 
                            {typeof change.old_value === 'object' ? (
                              <pre>{JSON.stringify(change.old_value, null, 2)}</pre>
                            ) : (
                              String(change.old_value)
                            )}
                            {idx < row.changes.length - 1 && <hr />}
                          </div>
                        ))}
                      </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          )}
          {!isAdd && auditData?.length > 0 && (
            <DialogActions>
                <Button
                  onClick={() => {
                    openEditDialogue(false);
                    setData({});
                  }}
                >
                  Cancel
                </Button>
            </DialogActions>
          )}
        </DialogContent>
      </Dialog>
      <AreYouSureDialog
        open={areYouSureOpen}
        page={page}
        message={`Are you sure you want to delete this ${
          page.charAt(page.length - 1) === "s"
            ? page.substring(0, page.length - 1)
            : page
        }?`}
        confirm={() => {
          handleDelete({confirmed: true});
          setAreYouSureOpen(false);
        }}
        cancel={() => setAreYouSureOpen(false)}
      />
    </>
  );
};

export default EditData;
